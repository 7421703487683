import React from 'react';
import { compose, getPropsFromStore } from '@reactorlib/core';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Login from './Login';
import LoginVerify from './LoginVerify';
import Footer from './Footer';
import LightWaveImg from '../shared/assets/LightWave.svg';

export const LoginPage = ({ session, login, history, classes, loginverify }) => {
  const message =
    history && history.location.pathname === '/login/referrer=signup'
      ? 'An account matches your email address or mobile number.'
      : '';

  window.scrollTo(0, 0);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        alignContent="center"
        className={classes.root}
      >
        <Grid item className={classes.body}>
          {!session.otpSent ? (
            <Login
              message={message}
              denied={session.denied}
              error={session.error}
              waiting={session.loading}
              onSubmit={login}
              username={session.email}
              blocked={session.blocked}
            />
          ) : (
            <LoginVerify
              message={message}
              denied={session.denied}
              error={session.error}
              waiting={session.loading}
              email={session.username}
              password={session.password}
              onSubmit={loginverify}
              blocked={session.blocked}
              otpblocked={session.otpblocked}
              remember={session.remember}
            />
          )}
        </Grid>
      </Grid>
      {/* <Paper elevation={0} className={classes.imageContainer} /> */}
      <Footer />
    </>
  );
};

export const styles = theme => ({
  root: {
    backgroundColor: 'white',

    // minHeight: '100vh',
  },
  body: {
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 5.25 * theme.spacing.unit,

    paddingBottom: 10 * theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  footer: {
    paddingTop: 10 * theme.spacing.unit,
    color: theme.palette.grey[600],
  },
  imageContainer: {
    backgroundImage: `url(${LightWaveImg})`,
    [theme.breakpoints.down('sm')]: {
      minHeight: 12 * theme.spacing.unit,
      padding: 0,
    },
    minHeight: 30 * theme.spacing.unit,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: 4.5 * theme.spacing.unit,
  },
});

export default compose(
  getPropsFromStore(['session', 'userProfile'], ['login', 'logout', 'loginverify']),
  withStyles(styles)
)(LoginPage);
