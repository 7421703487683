import React from 'react';
import {
  compose,
  withEffect,
  getPropsFromStore,
  withState,
  withCallbacks,
  withRouter,
} from '@reactorlib/core';
import OneServiceHeaderReact,
        { USER_SSO_STATUS, forceLogin } from '@soluto-asurion/one-service-header-react';
import {
 startMessagingSession,
 updateState,
 State,
} from '@soluto-asurion/one-service-messaging-react';

export const Header = ({
  status,
  setStatus,
  info,
  setInfo,
  session,
  setSessionUserStatus,
  handleUserData,
  history,
}) => {
  return (
    <>
     {
     <OneServiceHeaderReact
        partner="att"
        initiateMessaging={() => startMessagingSession('', forceLogin)}
        userStatus={handleUserData}
        environment={process.env.NODE_ENV}
        appName="photo-storage"
      />}
    </>
  );
};

export const updateUserStatus = ({
  status,
  info,
  setSessionUserStatus,
  isStatusUpdated,
  setIsStatusUpdated,
  userSignedOut,
  setUserSignedOut,
  history,
}) => {
  let userInfo = info;
  let userStatus = status;
  if (userStatus === USER_SSO_STATUS.SIGNED_IN && !isStatusUpdated) {
    setIsStatusUpdated(true);
    updateState(State.AuthenticatedUser);

    setSessionUserStatus({
      customerId: userInfo ? userInfo.customerId : '',
      accountId: userInfo ? userInfo.accountId : '',
      userStatus: userStatus ? userStatus : '',
      subscriptionType: userInfo ? userInfo.contract.type : '',
      username: userInfo ? userInfo.email : '',
      status: 'SIGNED_IN',
    });

    let preloginRedirected = localStorage.getItem('preloginRedirected');

    if (history && history.location.pathname !== '/gallery' && preloginRedirected !== '1')
      history.push('/gallery');
  } else if (userStatus === USER_SSO_STATUS.SIGNED_OUT && !userSignedOut) {
    updateState(State.Anonymous);

    localStorage.removeItem('preloginRedirected');
    setUserSignedOut(true);
    let path = history.location.pathname;
    if (history && (path === '/gallery' || path.includes('/education')))
      history.push('/logout');
  }
};

export const styles = theme => ({});

export default compose(
  withRouter,
  withState('info', ''),
  withState('status', ''),
  withState('isStatusUpdated', false),
  withState('userSignedOut', false),
  withCallbacks({
    handleUserData: ({ setInfo, setStatus }) => ({ userInfo, userStatus }) => {
      setInfo(userInfo);
      setStatus(userStatus);
      // updateState(State.Anonymous);
    },
  }),
  getPropsFromStore(['session'], ['setSessionUserStatus']),
  withEffect(updateUserStatus)
)(Header);
