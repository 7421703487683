import { createEntity } from '@reactorlib/core';

import {
  signup,
  verify,
  sendDownloadSms,
  provisionAccount,
} from '../../services/signupService';

export const initialState = {
  status: null,
  otpSent: false,
  hasSignedUp: false,
  hasVerified: false,
  isExists: false,
  error: false,
  email: null,
  mobile: null,
  password: null,
  loading: false,
  accountId: null,
  deviceId: null,
  uniqueId: null,
  auth: null,
  devicePasswordGuid: null,
  smsLinkSent: null,
  accountCreated: false,
  denied: false,
  blocked: false,
  subscriptionType: '',
};

export const onboarding = createEntity(
  {
    signup: [
      (state, { mobile, email, password }) => {
        return {
          ...state,
          loading: true,
          status: null,
          email: email,
          password: password,
          mobile: mobile,
          error: false,
          isExists: false,
          devicePasswordGuid: null,
          blocked: false,
        };
      },
      ({ mobile, email, password }, next) => {
        signup(mobile, email, password)
          .then(response => next(response))
          .catch(error => next({ error }));
      },
      (
        state,
        {
          status = null,
          denied = false,
          otpSent = false,
          hasSignedUp = false,
          hasVerified = false,
          isExists = false,
          error = false,
          accountId = null,
          email = '',
          auth = null,
          deviceId = null,
          uniqueId = null,
          storageDisplayName = null,
          subscriptionType = null,
          devicePasswordGuid = null,
          accountCreated = false,
          blocked = false,
        }
      ) => ({
        ...state,
        loading: false,
        denied,
        isExists:
          status != null &&
          status === 'ALREADY_PROVISIONED' &&
          otpSent === 'false' &&
          !error,
        hasSignedUp:
          status != null && otpSent === 'false' && !error && status !== 'BLOCKED',
        hasVerified: status != null && otpSent === 'true' && !error,
        error: error,
        auth: auth,
        accountId: accountId,
        email: email,
        deviceId: deviceId,
        uniqueId: uniqueId,
        storageDisplayName: storageDisplayName,
        subscriptionType: subscriptionType,
        devicePasswordGuid: devicePasswordGuid,
        status: status,
        blocked: status === 'BLOCKED',
        accountCreated: accountId ? true : false,
      }),
    ],
    verify: [
      (state, { mobile, email, password }) => {
        return {
          ...state,
          status: null,
          email: email,
          password: password,
          mobile: mobile,
          error: false,
          loading: true,
        };
      },
      ({ mobile, email, password, otp }, next) => {
        verify(mobile, email, password, otp)
          .then(response => next(response))
          .catch(error => next({ error }));
      },
      (
        state,
        {
          status = null,
          error = false,
          auth = null,
          accountId = null,
          deviceId = null,
          otpSent = false,
          storageDisplayName = null,
          subscriptionType = null,
          devicePasswordGuid = null,
          accountCreated = false,
          blocked = false,
        }
      ) => ({
        ...state,
        loading: false,
        error: error || status === 'CODE_NOT_FOUND',
        blocked: status === 'BLOCKED',
        auth: auth,
        accountId: accountId,
        deviceId: deviceId,
        storageDisplayName: storageDisplayName,
        subscriptionType: subscriptionType,
        devicePasswordGuid: devicePasswordGuid,
        status: status,
        accountCreated: accountId !== null ? true : false,
      }),
    ],

    sendDownloadSms: [
      (state, { mobile, accountId, deviceId, auth, customerId, anchorId }) => {
        return {
          ...state,
          sent: null,
          error: false,
          loading: true,
          // smsLinkSent: false,
        };
      },
      ({ mobile, accountId, deviceId, auth, customerId, anchorId }, next) => {
        sendDownloadSms(mobile, accountId, deviceId, auth, customerId, anchorId)
          .then(response => next(response))
          .catch(error => next({ error }));
      },
      (state, { sent = null, error = false }) => ({
        ...state,
        smsLinkSent: sent !== null && sent === 'true',
        loading: false,
      }),
    ],

    provisionAccount: [
      state => ({
        ...state,
        error: false,
      }),
      ({ accessToken, email }, next) => {
        console.log('onboarding provision account', accessToken);

        provisionAccount(accessToken, email)
          .then(response => {
            if (!response.user) {
              return next({
                status: response.status,
                statusText: response.statusText,
                error: true,
                ...response,
              });
            } else {
              next(response);
            }
          })
          .catch(error => next({ error }));
      },
      (
        state,
        {
          accountId,
          deviceId,
          storageDisplayName,
          subscriptionType,
          devicePasswordGuid,
          status,
          emailId,
          statusText,
          user,
          error = false,
          externalReferenceId,
        }
      ) => {
        return {
          ...state,
          accountId: accountId,
          deviceId: deviceId,
          storageDisplayName: storageDisplayName,
          subscriptionType: subscriptionType,
          devicePasswordGuid: devicePasswordGuid,
          status: status,
          email: emailId,
          statusText: statusText,
          user: user,
          externalReferenceId: externalReferenceId,
          error: error,
        };
      },
    ],

    // This entity should reset state on logout
    logout: () => ({ ...initialState }),
    //reset state
    resetOnboardingStore: () => ({ ...initialState }),
  },
  initialState
);

export default onboarding;
