import { createEntity } from '@reactorlib/core';

import { login, authenticate, verifyOTP, logout } from '../../services/authService';
import { createSession, getSession, destroySession } from '../../services/sessionService';

export const initialState = {
  auth: null,
  accountId: '',
  username: '',
  deviceId: '',
  denied: false,
  otpSent: false,
  error: false,
  loading: false,
  password: '',
  mdn: '',
  blocked: false,
  subscriptionType: '',
  uniqueId: '',
  otpblocked: false,
  startTime: '',
  otpdenied: false,
  newSession: false,
  userType: '',
  externalReferenceId: null,
};

export const session = createEntity(
  {
    login: [
      (state, { username, password, userType }) => {
        return {
          ...initialState,
          username,
          password,
          userType,
          loading: true,
        };
      },
      ({ username, password, remember, otp, userType }, next) => {
        login(username, password, otp)
          .then(response => {
            if (response.auth) {
              createSession({
                ...response,
                username,
                userType: 'Return',
                startTime: new Date().getTime(),
                newSession: true,
                remember,
              });
            }
            next({ username, password, remember, userType, ...response });
          })
          .catch(error => next({ error, username }));
      },
      (
        state,
        {
          auth = null,
          accountId = '',
          username,
          deviceId = '',
          denied = false,
          error = false,
          otpSent = false,
          password,
          status,
          mdn,
          subscriptionType = '',
          uniqueId = '',
          startTime = '',
          otpdenied = false,
          userType,
          newSession = false,
          remember = false,
          externalReferenceId = null,
        }
      ) => ({
        ...state,
        auth,
        accountId,
        username,
        deviceId,
        denied,
        otpdenied,
        status,
        error: error || status === 'undefined',
        blocked: status === 'BLOCKED',
        otpSent,
        password,
        mdn,
        loading: false,
        subscriptionType,
        startTime,
        userType,
        newSession: true,
        remember,
        externalReferenceId,
      }),
    ],

    generateAuth: [
      (state, { username }) => ({ ...initialState, username, loading: true }),
      ({ username, devicePassword, mdn, userType, externalReferenceId  }, next) => {
        authenticate(username, devicePassword, mdn, userType)
          .then(response => {
            if (response.auth)
              createSession({
                ...response,
                username,
                mdn,
                startTime: new Date().getTime(),
                userType: 'New',
                newSession: true,
              });
            next({ username,externalReferenceId, ...response });
          })
          .catch(error => next({ error, username }));
      },
      (
        state,
        {
          auth = null,
          accountId = '',
          deviceId = '',
          username,
          email,
          denied = false,
          error = false,
          otpSent = false,
          status = null,
          mdn = null,
          startTime = '',
          userType,
          newSession = false,
          externalReferenceId
        }
      ) => ({
        ...state,
        auth,
        accountId,
        deviceId,
        username,
        email: username,
        mdn,
        error: error || otpSent === 'undefined',
        denied,
        startTime,
        loading: false,
        userType,
        newSession: true,
        externalReferenceId
      }),
    ],

    loginverify: [
      (state, { email, password }) => {
        return {
          ...state,
          status: null,
          email: email,
          password: password,
          blocked: false,
          otpblocked: false,
          error: false,
          loading: true,
          remember: false,
          externalReferenceId: null,
        };
      },
      ({ email, password, remember, otp }, next) => {
        verifyOTP(email, password, otp)
          .then(response => {
            createSession({
              ...response,
              username: email,
              userType: 'Return',
              startTime: new Date().getTime(),
              newSession: true,
              remember,
            });
            next({ username: email, remember, ...response });
          })
          .catch(error => next({ error }));
      },
      (
        state,
        {
          status = null,
          error = false,
          auth = null,
          accountId = null,
          deviceId = null,
          mdn = '',
          storageDisplayName = null,
          subscriptionType = null,
          devicePasswordGuid = null,
          accountCreated = false,
          blocked = false,
          otpblocked = false,
          userType = '',
          newSession = false,
          remember = false,
          externalReferenceId = null,
        }
      ) => ({
        ...state,
        loading: false,
        error: error || status === 'CODE_NOT_FOUND',
        otpblocked: status === 'BLOCKED',
        auth: auth,
        accountId: accountId,
        deviceId: deviceId,
        mdn: mdn,
        storageDisplayName: storageDisplayName,
        subscriptionType: subscriptionType,
        devicePasswordGuid: devicePasswordGuid,
        status: status,
        accountCreated: accountId !== null ? true : false,
        userType: 'Return',
        newSession: true,
        remember,
        externalReferenceId,
      }),
    ],

    resetLogin: () => {
      const { username, remember } = getSession();
      return {
        ...initialState,
        otpSent: false,
        denied: false,
        blocked: false,
        username,
        otpblocked: false,
        otpdenied: false,
        remember: remember === 'true',
        externalReferenceId: null,
      };
    },

    resetOtp: () => {
      const {
        auth,
        accountId,
        username,
        deviceId,
        mdn,
        startTime,
        subscriptionType,
        userType,
        remember,
        externalReferenceId,
      } = getSession();
      return {
        subscriptionType,
        auth,
        accountId,
        username,
        deviceId,
        mdn,
        startTime,
        userType,
        remember,
        otpdenied: false,
        externalReferenceId,
      };
    },

    setSubscriptionType: (state, subType, userType) => {
      const { auth, accountId, username, deviceId, mdn, startTime } = getSession();
      createSession({
        subscriptionType: subType,
        auth,
        accountId,
        username,
        deviceId,
        mdn,
        startTime,
        userType: userType,
      });

      return {
        subscriptionType: subType,
        auth,
        accountId,
        username,
        deviceId,
        mdn,
        startTime,
        userType: userType,
      };
    },

    restoreSession: state => {
      const {
        auth,
        accountId,
        username,
        deviceId,
        mdn,
        userType,
        subscriptionType,
        startTime,
        remember,
      } = getSession();

      return auth !== null && auth !== 'undefined'
        ? {
            ...state,
            auth,
            accountId,
            username,
            deviceId,
            mdn,
            userType,
            subscriptionType,
            startTime,
            remember: remember === 'true',
          }
        : remember
        ? {
            ...state,
            username,
            remember: remember === 'true',
          }
        : state;
    },

    logout: () => {
      const { auth, accountId, username, deviceId } = getSession();
      logout(username, accountId, deviceId, auth);
      destroySession();
      return { ...initialState };
    },
  },
  initialState
);

export default session;
