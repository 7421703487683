import { createEntity } from '@reactorlib/core';

import { getStorageInfo } from '../../services/storageInfoService';

export const initialState = {
  storageLimitInMB: 0,
  storageUsedInMB: 0,
  numberOfFiles: 0,
  storageDisplayName: '',
  planName: '',
  error: false,
  loading: false,
};

export const storageInfo = createEntity(
  {
    fetchStorageInfo: [
      state => ({ ...state, loading: true }),
      ({ auth, accountId, customerId }, next) => {
        getStorageInfo(auth, accountId, customerId)
          .then(response => next(response))
          .catch(error => next({ error }));
      },
      (
        state,
        {
          storageLimitInMB = 0,
          storageUsedInMB = 0,
          numberOfFiles = 0,
          planName = '',
          storageDisplayName = 0,
          error = false,
        }
      ) => {
        return {
          ...state,
          storageLimitInMB,
          storageUsedInMB,
          numberOfFiles,
          storageDisplayName,
          planName,
          error,
          loading: false,
        };
      },
    ],
  },
  initialState
);

export default storageInfo;
