import mixpanel from 'mixpanel-browser';

import api from './config/api';
import brand from '../core/config/brand';

import { getClientInfo, generateDeviceID } from './clientInfoService';
import { osName } from 'react-device-detect';

mixpanel.init(api.mixpanel.token, { ip: true });

export const publishPeopleProps = async (session, user, storageInfo) => {
  const uniqueId = await generateDeviceID();
  mixpanel.identify(uniqueId);
  const externalReferenceId = {
    externalReferenceId: session.externalReferenceId
      ? session.externalReferenceId
      : undefined,
  };
  mixpanel.people.set({
    AccountId: session.accountId,
    UniqueId: uniqueId,
    ...externalReferenceId,
    UserType: user.toLowerCase().includes('return') ? 'Return' : 'New',
    carrierId: brand.carrierId,
    SubscriptionType:
      session.subscriptionType !== '' ? session.subscriptionType : storageInfo.planName,
    // $email: session.username,
    AppName: brand.appName,
    AppVersion: '1.0',
    browserName: getClientInfo().browserName,
    browserVersion: getClientInfo().browserVersion,
    OsVersion: getClientInfo().osVersion,
    AppVersionCode: '1.0',
    DeviceId: session.deviceId,
    FormFactor: getClientInfo().device,
    OmniChannel: 'Web',
    DeviceVendor: getClientInfo().deviceVendor || osName,
    DeviceModel: getClientInfo().deviceModel || getClientInfo().device,
    DeviceLocale: getClientInfo().locale,
    TotalFilesUploaded: storageInfo.numberOfFiles,
    TotalFilesSizeUploaded: storageInfo.storageUsedInMB,
  });

  mixpanel.people.unset('$email');
};
