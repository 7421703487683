const persona = {
  clientConfig: {
    authority: process.env.REACT_APP_PERSONA_OIDC_PROVIDER,
    client_id: process.env.REACT_APP_PERSONA_CLIENT_NAME || 'asurion-photos',
    redirect_uri: window.location.origin + '/autologin',
    silent_redirect_uri: window.location.origin + '/sso.html',
    post_logout_redirect_uri: window.location.origin + '/',
    response_type: 'code',
    scope: 'openid offline_access',
  },
  enabled: process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false,
};


export default persona;
