import { getClientInfo, generateDeviceID } from '../services/clientInfoService';

import brand from '../core/config/brand';
import api from './config/api';

import { getPersonaTokenSession } from './sessionService';
const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;
const personaToken = getPersonaTokenSession();

const version = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0';

export const createAccount = async (mobile, email, password) => {
  // let uniqueId = await generateDeviceID();

  const url = api.url.signup;

  const settings = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Asurion-WebApiKey': '2ec2b76AE37b373Db6bafd8c033ed8178C033Ed8',
      'X-Asurion-CarrierId': 'ATT',
    }),
    body: JSON.stringify({
      mdn: mobile,
      email: email,
      password: password,
    }),
  };

  const response = await fetch(url, settings).catch(function (error) {
    // Error handling here!
  });

  let json = await response.json();

  return json;
};

export const provisionAccount = async (accessToken, email) => {
  const uniqueId = await generateDeviceID();

  const provisionUrl = api.url.provision;
  const settings = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      ApiKey: api.key,
      CarrierId: brand.carrierId,
      Locale: getClientInfo().locale,
      'Content-Type': 'application/json',
      'Asurion-AccessToken': accessToken,
    }),
    body: JSON.stringify({
      carrierId: brand.carrierId,
      locale: getClientInfo().locale,
      uniqueId: uniqueId,
      devices: [
        {
          applicationDetails: {
            appType: 'WEB',
            appVersion: version,
            deviceOsType: getClientInfo().osName,
          },
          displayName: 'WEB',
          duid: uniqueId,
        },
      ],
    }),
  };

  let response = await fetch(provisionUrl, settings).catch(error => {
    // Error handling here!
    console.log(error);
    return { error: true, status: 500, statusText: error.message };
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    return await response;
  }
};

export const validateOTP = async (mobile, email, password, otp) => {
  const url = api.url.validate;

  const settings = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Asurion-WebApiKey': '2ec2b76AE37b373Db6bafd8c033ed8178C033Ed8',
      'X-Asurion-CarrierId': 'ATT',
    }),
    body: JSON.stringify({
      mdn: mobile,
      email: email,
      password: password,
      otpcode: otp,
    }),
    //   credentials: 'include',
  };

  const response = await fetch(url, settings);
  //const respjson = await response.json();

  //console.log(respjson.headers.get('set-cookie')); // undefined
  //console.log(document.cookie); // nope

  return await response.json();
};

export const verify = async (mobile, email, password, otp) => {
  const { customer, accountId, contract } = await validateOTP(
    mobile,
    email,
    password,
    otp
  );

  return {
    customer,
    accountId,
    contract,
  };
};

export const signup = async (mobile, email, password) => {
  // const { account, errors } = await createAccount(mobile, email, password);

  const { account, errors } = await createAccount(mobile, email, password);

  console.log('console loggining' + JSON.stringify(account));

  if (errors && errors[0].message) {
    console.log('Here in Errors');
    return {
      denied: true,
      errorMessage: errors[0].message,
    };
  }
  console.log('console loggining 3' + JSON.stringify(account));

  return {
    otpSent: 'true',
    mobile,
    email,
    password,
  };
};

export const sendDownloadSms = async (
  mobile,
  accountId,
  deviceId,
  auth,
  customerId,
  anchorId
) => {
  const url = api.url.sendDownloadSMS;

  let uniqueId = await generateDeviceID();
  const settings = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ApiKey: api.key,
      Locale: getClientInfo().locale,
      CarrierId: brand.carrierId,
      Authorization:
        enablePersona && personaToken && personaToken.accessToken
          ? personaToken.accessToken
          : auth,
      DeviceId: deviceId,
      AccountId: accountId,
      UseCognito: 'WEB',
      CustomerId: customerId,
      'X-Asurion-WebApiKey': api.asurionWebApiKey,
      'X-Asurion-CarrierId': brand.carrierId,
    }),
    body: JSON.stringify({
      mdn: mobile,
      uniqueId: uniqueId,
      link: brand.downloadAppLink,
      carrierId: brand.carrierId,
      accountId: anchorId,
    }),
  };

  const response = await fetch(url, settings);

  return await response.json();
};
