import { compose, getPropsFromStore } from '@reactorlib/core';
import { publishPeopleProps } from '../services/mixPanelService';
import { withRouter } from '@reactorlib/core/lib/routing';

export const LogoutPage = ({ session, history, userProfile, logout, storageInfo }) => {
  if (history.location.pathname !== '/landing') {
    if (session.auth && userProfile.userType)
      publishPeopleProps(session, userProfile.userType, storageInfo);
    if (history.location.pathname === '/logout') {
      logout();
      history.push('/landing');
    }
  }
  return null;
};

export default compose(
  withRouter,
  getPropsFromStore(['session', 'userProfile', 'storageInfo'], ['login', 'logout'])
)(LogoutPage);
