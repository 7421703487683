import React, { Component } from 'react';
import { compose, getPropsFromStore } from '@reactorlib/core';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import loader from './assets/asurion-loader.gif';
import { UserManager } from 'oidc-client';
import personaConfig from '../core/config/persona';

export class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorState: false,
    };

    this.authClient = new UserManager(personaConfig.clientConfig);
  }

  setLoading = state => {
    this.setState({
      loading: state,
    });
  };

  getState = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('state');
  };

  cleanUrl = () => {
    window.history.replaceState(
      null,
      '',
      window.location.href.replace(window.location.search, '')
    );
  };

  hasCodeInUrl = location => {
    if (location) {
      const searchParams = new URLSearchParams(location.search);
      const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

      return (
        searchParams.get('code') ||
        searchParams.get('id_token') ||
        searchParams.get('session_state') ||
        hashParams.get('code') ||
        hashParams.get('id_token') ||
        hashParams.get('session_state')
      );
    } else {
      return false;
    }
  };

  componentDidMount() {
    // If Auth exists in store, navigate to gallery
    if (this.props.session.auth) {
      this.setLoading(false);
      this.props.history.push('/gallery');
    } else {
      this.checkAuth();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.onboarding !== nextProps.onboarding) {
      // check if user data is available post provison API call
      if (nextProps.onboarding.user) {
        const provisionData = nextProps.onboarding;
        this.createProvison(provisionData);

        // Call - AuthToken API
        this.props.generateAuth({
          username: provisionData.email,
          devicePassword: provisionData.devicePasswordGuid,
          mdn: '',
          userType: provisionData.status,
          externalReferenceId: provisionData.externalReferenceId,
        });
      } else if (nextProps.onboarding.error) {
        this.setLoading(false);
        this.setState({ errorState: true });
      }
    }
  }

  checkAuth = () => {
    this.setLoading(true);

    this.authClient
      .signinSilent()
      .then(user => {
        this.setLoading(true);

        console.log('processed silent auth');

        if (user) {
          const profile = user.profile || {};

          this.props.createPersonaProfile({
            personaAuthenticated: true,
            accessToken: user.access_token,
            refreshToken: user.refresh_token,
            clientAdapter: this.authClient,
          });

          this.props.provisionAccount({
            accessToken: user.access_token,
            email: profile.principal_identifier,
          });

          // this.cleanUrl();
        }
      })
      .catch(() => {
        this.authClient
          .signinRedirectCallback()
          .then(user => {
            this.setLoading(true);

            console.log('processed redirect');

            if (user) {
              const profile = user.profile || {};

              this.props.createPersonaProfile({
                personaAuthenticated: true,
                accessToken: user.access_token,
                refreshToken: user.refresh_token,
                clientAdapter: this.authClient,
              });

              this.props.provisionAccount({
                accessToken: user.access_token,
                email: profile.principal_identifier,
              });

              // this.cleanUrl();
            }
          })
          .catch(() => {
            console.log('not redirect. starting auth redirect');
            const _state = this.getState();
            this.authClient.signinRedirect({ state: _state }).catch(() => {
              this.setLoading(false);
              this.setState({ errorState: true });
            });
          });
      });
  };

  createProvison = provisionData => {
    // set storage values
    localStorage.setItem('subscriptionType', provisionData.subscriptionType);
    localStorage.setItem('externalReferenceId', provisionData.externalReferenceId);
    localStorage.setItem('status', provisionData.status);

    localStorage.removeItem('autoLoggingIn');
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.loading ? (
          <Grid container className={classes.root}>
            <Grid item xs>
              <img src={loader} className={classes.loader} alt="loader" />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#fff',
  },
  loader: {
    width: 37.5 * theme.spacing.unit,
    padding: 10 * theme.spacing.unit,
    margin: '100px auto',
    display: 'block',
  },
});

export default compose(
  withStyles(styles),
  getPropsFromStore(
    ['onboarding', 'persona', 'session', 'userProfile'],
    ['createPersonaProfile', 'provisionAccount', 'generateAuth']
  )
)(GetStarted);
