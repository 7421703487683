import React from 'react';
import { withRouter } from 'react-router';
import {
  compose,
  withState,
  withCallbacks,
  withEffect,
  withCleanup,
  getPropsFromStore,
} from '@reactorlib/core';
import {
  Paper,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ExternalLink, ViewablePasswordField } from '@reactorlib/mui';
import { withStyles } from '@material-ui/core/styles';
import brandIcon from './assets/app-icon.svg';

import api from '../services/config/api';
import brand from '../core/config/brand';
import CodeErrorPage from '../welcome/CodeErrorPage';
import { publishAnonEvent } from '../services/reportingService';
import { isValidEmail } from '../services/formValidationService';

export const Login = ({
  message,
  denied,
  error,
  waiting,
  email,
  password,
  remember,
  emailError,
  handleChangeEmail,
  handleChangePassword,
  handleChangeRemember,
  handleFocusEmail,
  handleFocusPassword,
  handleClickForgotPassword,
  handleClickDifferentUser,
  handleSubmit,
  classes,
  emailFocus,
  inputAutofilledByBrowser,
  passwordFocus,
  blocked,
  username,
  session,
  onboarding,
  resetOnboardingStore,
  keyPress,
  setKeyPress,
  hasRemembered,
  hasForgotten,
}) => {
  // TODO: Please check this, the format is not a normal callback, it looks like an HOC
  const handleClickSignup = ({ history, onboarding }) => event => {
    event.preventDefault();
    publishAnonEvent({ eventType: 'LinkClicked', link: 'SignUp', screen: 'SignIn' });
    onboarding.resetOnboardingStore();
    history.push('/welcome/reset=t');
  };

  return (
    <>
      {blocked ? (
        <Paper elevation={1} className={classes.root}>
          <CodeErrorPage
            page="login"
            errorcase="account"
            email={username}
            uniqueId={session.uniqueId}
          />
        </Paper>
      ) : (
        <Paper elevation={1} className={classes.root}>
          <div align="center">
            <img src={brandIcon} className={classes.logo} alt="logo" />
          </div>
          <div align="center">
            <img src={brand.carrierLogo.photosicon} alt="logo" />
          </div>
          <Typography variant="h6" align="center" className={classes.signInHeader}>
            Sign In
          </Typography>

          {message && (
            <Typography variant="body2" className={classes.message}>
              {message}
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            {(session.remember || hasRemembered) && !hasForgotten ? (
              <Typography
                variant="body1"
                component="div"
                className={classes.rememberedUser}
              >
                <Typography className={classes.textFieldLabels}>Email Address</Typography>
                <Typography variant="body1" className={classes.rememberedEmail}>
                  {formatMaskedEmail(email)}
                  <Typography
                    variant="body1"
                    component="span"
                    className={classes.differentUser}
                  >
                    Not you?{' '}
                    <Link href="#" onClick={handleClickDifferentUser} variant="body1">
                      Sign in as another user
                    </Link>
                  </Typography>
                </Typography>
              </Typography>
            ) : (
              <Typography className={classes.textFieldLabels}>Email Address</Typography>
            )}
            <TextField
              id="email"
              value={email}
              error={emailError}
              helperText={
                emailError ? 'Invalid email address -- please check your entry.' : ''
              }
              onChange={handleChangeEmail}
              onFocus={handleFocusEmail}
              className={
                (session.remember || hasRemembered) && !hasForgotten
                  ? classes.hidden
                  : classes.textField
              }
              required
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                required: false,
                shrink: email || emailFocus || inputAutofilledByBrowser ? true : false,
              }}
            />
            <Typography className={classes.textFieldLabels}>Password</Typography>
            <ViewablePasswordField
              autoFocus
              id="password"
              showButtonLabel="Show"
              hideButtonLabel="Hide"
              value={password}
              onChange={handleChangePassword}
              onFocus={handleFocusPassword}
              className={classes.textField}
              classes={{ toggle: classes.passwordToggle }}
              required
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                required: false,
                shrink:
                  password || passwordFocus || inputAutofilledByBrowser ? true : false,
              }}
            />
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item className={classes.options}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={handleChangeRemember}
                      color="secondary"
                    />
                  }
                  label="Remember email"
                  className={classes.checkbox}
                />
              </Grid>
              <Grid item className={classes.options}>
                <Link
                  href="/welcome"
                  variant="body2"
                  onClick={handleClickForgotPassword}
                  className={classes.forgotPwdText}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.error}>
                <Typography variant="caption" color="error">
                  {hasForgotten
                    ? ''
                    : denied
                    ? 'Email and password do not match-- please try again.'
                    : error
                    ? 'Unable to connect-- please try again.'
                    : ' '}
                </Typography>
              </Grid>
              <Grid item container>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={waiting || !email || !password}
                  className={classes.submit}
                >
                  {!waiting ? (
                    'Continue'
                  ) : (
                    <CircularProgress size={24} className={classes.progress} />
                  )}
                </Button>
              </Grid>
              <Grid container item justify="center">
                <Typography variant="body2" className={classes.signinText}>
                  Not registered?{' '}
                  <Link href="/welcome?reset=t" onClick={handleClickSignup}>
                    Sign up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>

          <Typography variant="caption" align="center" className={classes.footnote}>
            By logging in, I agree to the
            <ExternalLink
              href={brand.content.menu.public[1].link}
              onClick={() =>
                publishAnonEvent({
                  eventType: 'LinkClicked',
                  link: brand.content.menu.public[1].linkName,
                  screen: 'SignIn',
                })
              }
            >
              Terms of Service
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              href={brand.content.menu.public[2].link}
              onClick={() =>
                publishAnonEvent({
                  eventType: 'LinkClicked',
                  link: brand.content.menu.public[2].linkName,
                  screen: 'SignIn',
                })
              }
            >
              Privacy Policy
            </ExternalLink>
            .
          </Typography>
        </Paper>
      )}
    </>
  );
};

export const formatMaskedEmail = email => email.substring(0, 3) + '***';

export const checkBrowserAutoFill = ({
  inputAutofilledByBrowser,
  setInputAutofilledByBrowser,
  autoFilledTimer,
  setAutoFilledTimer,
}) => {
  if (document.getElementById('email') != null) {
    var startAutoFillTimer = setTimeout(() => {
      if (
        window.getComputedStyle(document.getElementById('email')).backgroundColor !==
        'rgba(0, 0, 0, 0)'
      ) {
        setInputAutofilledByBrowser(true);
      } else {
        setInputAutofilledByBrowser(false);
      }
    }, 1000);
    if (inputAutofilledByBrowser) {
      setAutoFilledTimer(startAutoFillTimer);
    }
  }
};
export const clearTimer = ({ autoFilledTimer }) => {
  clearTimeout(autoFilledTimer);
};

export const checkDidLoginFail = ({ blocked, denied, keyPress }) => {
  if (denied && !blocked && !keyPress) {
    publishAnonEvent({
      eventType: 'MessageShown',
      message: 'EmailPasswordMismatch',
      screen: 'SignIn',
    });
    publishAnonEvent({
      eventType: 'LoginFail',
      httpStatusMessage: 'Unauthorized',
      httpStatusCode: '401',
    });
  }
};

export const checkRemembered = ({
  session,
  setEmail,
  hasRemembered,
  setHasRemembered,
  hasForgotten,
}) => {
  if (session.remember && !hasRemembered && !hasForgotten) {
    setEmail(session.username);
    setHasRemembered(true);
  }

  if (hasForgotten && hasRemembered) {
    setEmail('');
    setHasRemembered(false);
  }
};

export const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: 'none',
    },
    padding: 4 * theme.spacing.unit,
    paddingBottom: 0,
    width: 50 * theme.spacing.unit,
    minHeight: 61 * theme.spacing.unit,
  },
  logo: {
    maxHeight: 8 * theme.spacing.unit,
  },
  passwordToggle: {
    color: theme.palette.secondary.main,
    fontSize: '0.875rem',
    textTransform: 'none',
  },
  options: {
    paddingTop: 1 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  checkbox: {
    height: 3 * theme.spacing.unit,
  },
  submit: {
    minWidth: 14 * theme.spacing.unit,
  },
  progress: {
    color: theme.palette.primary.contrastText,
  },
  error: {
    minHeight: 2 * theme.spacing.unit,
    marginTop: -1 * theme.spacing.unit,
    marginBottom: 1 * theme.spacing.unit,
  },
  footnote: {
    paddingTop: 4 * theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    color: theme.palette.secondary.main,
    fontSize: 2 * theme.spacing.unit,
  },
  emailState: {
    padding: 1 * theme.spacing.unit,
  },
  rememberedUser: {
    paddingTop: 2 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  rememberedEmail: {
    fontSize: 2 * theme.spacing.unit,
    paddingTop: 0.5 * theme.spacing.unit,
  },
  differentUser: {
    display: 'inline',
    fontSize: 2 * theme.spacing.unit,
    float: 'right',
  },
  hidden: {
    display: 'none',
  },
  signinText: {
    marginTop: '10px',
    fontSize: 2 * theme.spacing.unit,
  },
  forgotPwdText: {
    fontSize: 2 * theme.spacing.unit,
  },
  signInHeader: {
    fontWeight: 'bold',
  },
  textFieldLabels: {
    fontSize: '1rem',
    lineHeight: '150%',
    marginTop: 1.5 * theme.spacing.unit,
    color: theme.palette.secondary.main,
  },
});

export default compose(
  withRouter,
  withState({
    email: '',
    password: '',
    emailError: false,
    remember: true,
    inputAutofilledByBrowser: false,
    autoFilledTimer: '',
    mobileFocus: false,
    emailFocus: false,
    passwordFocus: false,
    keyPress: false,
    hasRemembered: false,
    hasForgotten: false,
  }),
  getPropsFromStore(['session', 'onboarding'], ['login', 'resetOnboardingStore']),
  withCallbacks({
    handleChangeEmail: ({ setEmail, setKeyPress }) => event => {
      setEmail(event.target.value);
      setKeyPress(true);
    },
    handleChangePassword: ({ setPassword, setKeyPress }) => event => {
      setPassword(event.target.value);
      setKeyPress(true);
    },
    handleChangeRemember: ({ remember, setRemember }) => () => {
      setRemember(remember => !remember);
    },
    handleFocusEmail: ({ setEmailFocus }) => event => setEmailFocus(true),
    handleFocusPassword: ({ setPasswordFocus }) => event => setPasswordFocus(true),
    // handleClickSignup: ({ history, resetOnboardingStore }) => event => {
    //   event.preventDefault();
    //   publishAnonEvent({ eventType: 'LinkClicked', link: 'SignUp', screen: 'SignIn' });
    //   resetOnboardingStore();
    //   history.push('/welcome/reset=t');
    // },
    handleClickForgotPassword: ({ email, remember, history }) => event => {
      event.preventDefault();
      api.resetPasswordVerify = 'true';
      publishAnonEvent({
        eventType: 'LinkClicked',
        link: 'ForgotPassword',
        screen: 'SignIn',
      });
      history.push({
        pathname: '/resetpw',
        state: { email, remember },
      });
    },
    handleClickDifferentUser: ({ setHasForgotten, setPassword }) => event => {
      event.preventDefault();
      setPassword('');
      setHasForgotten(true);
    },
    handleSubmit: ({
      email,
      password,
      remember,
      setEmailError,
      onSubmit,
      setHasForgotten,
      setKeyPress,
    }) => event => {
      event.preventDefault();

      if (!isValidEmail(email)) {
        setEmailError(true);
        return false;
      } else {
        setEmailError(false);
      }
      publishAnonEvent({
        eventType: 'ButtonPressed',
        button: 'SignIn',
        screen: 'SignIn',
      });
      onSubmit({ username: email, password, remember });
      setKeyPress(false);
      setHasForgotten(false);
    },
  }),
  withEffect(checkDidLoginFail),
  withEffect(checkBrowserAutoFill, true),
  withEffect(checkRemembered),
  withEffect(
    () => publishAnonEvent({ eventType: 'ScreenShown', screen: 'SignIn' }),
    true
  ),
  withCleanup(clearTimer),
  withStyles(styles)
)(Login);
