import { http } from '@reactorlib/core';

import { getClientInfo, generateDeviceID } from './clientInfoService';
import { osName } from 'react-device-detect';

import api from './config/api';
import brand from '../core/config/brand';

import { getPersonaTokenSession } from './sessionService';
const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;
const personaToken = getPersonaTokenSession();

// Authenticated Calls
const postLoginUrl = api.url.eventApiV1;

// Pre Login Calls - No auth required.

const preLoginUrl = api.url.eventApiV2;

const requestHeaders = {
  CarrierId: brand.carrierId,
  Locale: brand.locale,
};

const eventDetail = {
  eventTime: Date.now(),
  osVersion: getClientInfo().osVersion,
  appVersion: '1.0',
  appVersionCode: '100',
  browserName: getClientInfo().browserName,
  browserVersion: getClientInfo().browserVersion,
  reportingAnalyticsVersion: 5,
  sim: 'NotExist',
  simCarrier: brand.carrierId,
};

const requestBody = {
  os: getClientInfo().osName,
  sourceType: 'Web',
  omniChannel: 'Web',
  formFactor: getClientInfo().device,
  deviceModel: getClientInfo().deviceModel || getClientInfo().device,
  vendor: getClientInfo().deviceVendor || osName,
  locale: getClientInfo().locale,
  packageName: 'MVP1',
  appName: brand.appName,
  simCompatible: 'false',
};

export const publishEvent = async (session, events, user) => {
  if (process.env.NODE_ENV !== 'production') console.log('Reporting event', events);
  const uniqueId = await generateDeviceID();
  const externalReferenceId = {
    externalReferenceId: session.externalReferenceId
      ? session.externalReferenceId
      : undefined,
  };
  let userType;
  let ustate;

  if (user && user !== undefined) {
    if (user.userType && user.userType !== '') {
      ustate = user.userType;
    } else if (typeof user === 'string' && user !== '') {
      ustate = user;
    } else {
      ustate = session.userType;
    }
  } else if (session.userType && session.userType !== '') {
    ustate = session.userType;
  }
  if (ustate) {
    userType = {
      userType: ustate.toLowerCase().includes('return') ? 'Return' : 'New',
    };
  }

  let headers = {
    ...requestHeaders,
    Authorization:
      enablePersona && personaToken && personaToken.accessToken
        ? personaToken.accessToken
        : session.auth,
    ApiKey: api.key,
    AccountId: session.accountId,
    DeviceId: session.deviceId,
    UseCognito: 'WEB',
    CustomerId: session.customerId,
    'X-Asurion-WebApiKey': api.asurionWebApiKey,
    'X-Asurion-CarrierId': brand.carrierId,
  };
  const eventsApi = http(postLoginUrl, headers);

  await eventsApi
    .post({
      body: {
        ...requestBody,
        ...externalReferenceId,
        uniqueId: uniqueId,
        ...userType,
        events: [
          {
            ...events,
            ...eventDetail,
            subscriptionType:
              user && user.subscriptionType && user.subscriptionType !== ''
                ? user.subscriptionType
                : session.subscriptionType,
          },
        ],
      },
    })
    .catch(function (error) {
      // Error handling here!
    });
};

export const publishAnonEvent = async (events, user, uniqueId, accountId) => {
  if (process.env.NODE_ENV !== 'production')
    console.log('Reporting anonymous event', events);

  if (!uniqueId) {
    uniqueId = await generateDeviceID();
  }

  let userType;

  if (user && user !== undefined) {
    console.log('user = ', user);
    userType = {
      userType: user && user.toLowerCase().includes('return') ? 'Return' : 'New',
    };
  }

  let headers = {
    Authorization:
      enablePersona && personaToken && personaToken.accessToken
        ? personaToken.accessToken
        : api.reporting.auth,
    ApiKey: api.reporting.key,
    ...requestHeaders,
  };

  const eventsApi = http(preLoginUrl, headers);

  let body = {
    ...requestBody,
    uniqueId: uniqueId,
    ...userType,
    events: [
      {
        ...events,
        ...eventDetail,
      },
    ],
  };

  if (accountId) {
    body.accountId = accountId;
  }

  await eventsApi
    .post({
      body: body,
    })
    .catch(function (error) {
      // Error handling here!
    });
};
