import React from 'react';
import OneServiceFooterReact from '@soluto-asurion/one-service-footer-react';
import { compose } from '@reactorlib/core';
import { startMessagingSession } from '@soluto-asurion/one-service-messaging-react';
import { forceLogin } from '@soluto-asurion/one-service-header-react';

export const Footer = () => {
  return (
    <>
      {<OneServiceFooterReact
        partner="att"
        initiateMessaging={() => startMessagingSession('', forceLogin)}
        isProduction={process.env.NODE_ENV === 'production'}
        appName="photo-storage"
      />}
      {/* <ATTMessaging /> */}
    </>
  );
};

export default compose()(Footer);
