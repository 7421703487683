// Product icons
import logo from '../assets/att.svg';
import logoNoText from '../assets/logo-notext.png';
import whiteLogo from '../assets/att.svg';
import darkLogo from '../assets/att.svg';
import icon from '../assets/att.svg';

// Carrier fonts
import ATTSansWebRegular from '../assets/fonts/ATTAleckSans_Rg.ttf';
import ATTSansWebMedium from '../assets/fonts/ATTAleckSans_Md.ttf';
import ATTSansWebBold from '../assets/fonts/ATTAleckSans_Bd.ttf';

export default {
  appName: 'AT&T Photo Storage',
  carrier: 'AT&T',
  carrierId: 'aff',
  locale: 'en_US',
  downloadAppLink: process.env.REACT_APP_DOWNLOADAPP_LINK || '',
  downloadNowLink: process.env.REACT_APP_DOWNLOADNOW_LINK || '',
  downloadAppGalleryPageLink:
    process.env.REACT_APP_DOWNLOADAPP_GALLERY_LINK ||
    'http://pss-vault-identifyme-qa.s3-website-us-east-1.amazonaws.com/ATT/share/origin.html?channel=Web&link=LandingPageDownloadApp&variant=1&theme=WebResponsiveGalleryPageDownloadApp',
  downloadAppStoreLink: process.env.REACT_APP_DOWNLOADAPPSTORE_LINK || '',
  downloadPlayStoreLink: process.env.REACT_APP_DOWNLOADPLAYSTORE_LINK || '',
  appLink: 'asurionmemories://launch-app',
  carrierLogo: {
    light: whiteLogo,
    dark: darkLogo,
    reg: icon,
    regNoText: logoNoText,
  },
  fonts: [
    {
      fontFamily: 'ATTAleckSans',
      fontWeight: 500,
      src: `url(${ATTSansWebRegular}) format("truetype")`,
    },
    {
      fontFamily: 'ATTAleckSans',
      fontWeight: 700,
      src: `url(${ATTSansWebMedium}) format("truetype")`,
    },
    {
      fontFamily: 'ATTAleckSans',
      fontWeight: 900,
      src: `url(${ATTSansWebBold}) format("truetype")`,
    },
  ],
  supportEmail: {
    address: 'memoriessupport@asurion.com',
    bodyText:
      'mailto:memoriessupport@asurion.com?subject=Account%20Issue&body=Please%20include%20the%20email%20or%20phone%20number%20associated%20with%20your%20account%20and%20a%20description%20of%20your%20issue.%0A%0A%0A%0A%0A%0A%0A%0A%0APlease%20do%20not%20delete%20below%3A%0A----------------------------------------------------------%0AEMAIL%20%2F%20DEVICE_ID',
  },
  content: {
    menu: {
      public: [
        // { text: 'Share Complete Storage' },
        {
          text: 'Help Center',
          link: 'https://www.asurion.com/tech-help/',
          linkName: 'HelpCenter',
          target: '_blank',
        },
        {
          text: 'Terms of Service',
          link: 'https://www.asurion.com/terms-conditions/',
          linkName: 'TermsOfService',
          target: '_blank',
        },
        {
          text: 'Privacy Policy',
          div: 'true',
          link: 'https://www.asurion.com/privacy-policy/',
          linkName: 'PrivacyPolicy',
          target: '_blank',
        },
      ],
      protected: [
        {
          text: 'Change Password',
          link: '/changepw',
          linkName: 'ChangePassword',
          target: 'internal',
        },
        {
          text: 'Give Feedback',
          link:
            'mailto:memoriessupport@asurion.com?subject=Feedback%20for%20Memories&body=Please%20tell%20us%20about%20any%20errors%2C%20glitches%2C%20or%20share%20your%20positive%20experience%20below.%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0A%0APlease%20do%20not%20delete%20below%3A%0A----------------------------------------------------------%0ADEVICE_ID%20%20%2F%20ACCOUNT_ID',
          linkName: 'GiveFeedback',
          target: '_self',
        },
        { text: 'Sign Out', link: '/logout', linkName: 'LogOut', target: 'internal' },
      ],
    },
    footer: {
      actionLinks: [
        {
          href: 'https://www.asurion.com/faqs/mobile-insurance/',
          text: 'Help Center/FAQ',
          linkName: 'FAQ',
        },
        {
          href: 'https://www.asurion.com/claims/',
          text: 'File or Track Claim',
          linkName: 'fileaclaim',
        },
        {
          href: 'https://www.asurion.com/faqs/tech-help/',
          text: 'Schedule a Setup Session',
          linkName: 'SetUp',
        },
      ],
      downloadLinks: [
        {
          href: 'http://bit.ly/Memories',
          text: 'Asurion Friends',
          icon: logo,
          linkName: 'Memories',
        },
        {
          href: 'http://bit.ly/Memories',
          text: 'Asurion Memories',
          icon: icon,
          linkName: 'Memories',
        },
      ],

      serviceLinks: [
        {
          href: 'https://www.asurion.com/terms-conditions/',
          text: 'Program Terms & Conditions',
          linkName: 'ProgramTermsOfService',
        },
        {
          href: 'https://www.asurion.com/terms-conditions/',
          text: 'Terms of Use',
          linkName: 'TermsOfService',
        },
        {
          href: 'https://www.asurion.com/privacy-policy/',
          text: 'Privacy Policy',
          linkName: 'PrivacyPolicy',
        },
        {
          href: 'https://www.asurion.com/terms-conditions/#trademarks',
          text: 'Trademarks',
          linkName: 'Trademarks',
        },
        {
          href: 'https://www.asurion.com/government-information/',
          text: 'Government Information',
          linkName: 'GovInfo',
        },
      ],
      pageNames: [
        {
          path: '/welcome',
          name: 'Welcome',
        },
        {
          path: '/login',
          name: 'SignIn',
        },
        {
          path: '/resetpw',
          name: 'ResetPassword',
        },
        {
          path: '/changepw',
          name: 'ResetPassword',
        },
        {
          path: '/logout',
          name: 'SignIn',
        },
        {
          path: '/thankyou',
          name: 'OnboardingConfirmation',
        },
        {
          path: '/gallery',
          name: 'Gallery',
        },
      ],
    },
  },
  imageLightBox: {
    exifProperties: {
      details: "Details",
      dateTaken: "DATE TAKEN",
      mediaInfo: "MEDIA INFO",
      pixels: "Pixels",
      resolution: "Resolution",
      fileSize: "Filesize",
      uploadDate: "UPLOAD DATE",
      cameraInfo: "CAMERA INFO",
      stop: "Stop",
      aperture: "Aperture",
      focalLength: "Focal length",
      filmSpeed: "Film speed",
      location: "LOCATION"
    }
  },
};
