import { createEntity } from '@reactorlib/core';

import { verify } from '../../services/resetPasswordService';

export const initialState = {
  username: null,
  status: null,
  hasSentPassword: false,
  failed: false,
  blocked: false,
  loading: false,
  error: '',
};

export const resetPassword = createEntity(
  {
    verifyCode: [
      state => ({ ...state, loading: true }),
      ({ username, password }, next) => {
        verify(username, password)
          .then(response => next(response))
          .catch(error => next({ error }));
      },
      (
        state,
        { username, status = null, hasSentPassword = false, failed = false, blocked = false, error }
      ) => {
        return {
          ...state,
          username,
          status,
          hasSentPassword: status != null && status === 'SUCCESS',
          failed: status != null && status !== 'SUCCESS',
          blocked: status === 'BLOCKED',
          loading: false,
          error,
        };
      },
    ],
  },
  initialState
);

export default resetPassword;
