export const sessionProps = [
  'auth',
  'accountId',
  'anchorId',
  'customerId',
  'username',
  'deviceId',
  'mdn',
  'subscriptionType',
  'startTime',
  'status',
  'userType',
  'remember',
  'externalReferenceId',
  'ssoauthenticated',
];
export const rememberProps = [
  'username',
  'mdn',
  'subscriptionType',
  'startTime',
  'userType',
  'remember',
  'externalReferenceId',
  'ssoauthenticated',
  'anchorId',
  'accountId',
  'auth',
];

export const createSession = data => {
  if (!localStorage) return false;
  try {
    sessionProps.forEach(k => {
      localStorage.setItem(k, data[k]);
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const getSession = () => {
  if (!localStorage) return {};

  try {
    return sessionProps.reduce(
      (session, k) => ({
        ...session,
        [k]: localStorage.getItem(k),
      }),
      {}
    );
  } catch (e) {
    return {};
  }
};

export const destroySession = () => {
  if (!localStorage) return false;

  try {
    sessionProps.forEach(k => {
      if (rememberProps.indexOf(k) === -1) localStorage.removeItem(k);
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const createPersonaTokenSession = (accessToken, refreshToken) => {
  if (!localStorage) return false;

  try {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  } catch (e) {
    return {};
  }
};

export const getPersonaTokenSession = () => {
  if (!localStorage) return {};

  try {
    return {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    };
  } catch (e) {
    return {};
  }
};

export const destroyPersonaTokenSession = () => {
  if (!localStorage) return {};

  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return true;
  } catch (e) {
    return false;
  }
};
