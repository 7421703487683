import React, { lazy } from 'react';
import { withRouter } from 'react-router-dom';
import { compose, RouterOutlet, getPropsFromStore, withEffect } from '@reactorlib/core';

// import Header from './Header.js';
// import LoggedOut from './LoggedOut';
import LoginPage from './LoginPage';
// import LandingPage from './LandingPage';
import initAnalytics from '@soluto-asurion/one-service-analytics-react';
//import { ATTMessaging } from '@soluto-asurion/one-service-messaging-react';

export const isAuthenticated = ({ auth }) => auth !== null;
export const isNotAuthenticated = ({ auth }) => !isAuthenticated({ auth });
// TO DO : Revert to SSOAUTHENTICATED ONCE WE IMPLEMENT ASURION ID
export const isSSOAuthenticated = ({ ssoauthenticated }) => ssoauthenticated !== null;
export const isSSONotAuthenticated = ({ ssoauthenticated }) =>
  !isSSOAuthenticated({ ssoauthenticated });

// These are fetched only when their route is activated
// const LandingPage = lazy(() => import("../landing/LandingPage"));
const GalleryPage = lazy(() => import('../gallery/GalleryPage'));
const EducationPage = lazy(() => import('../education/EducationPage'));
const ContentArticlePage = lazy(() => import('../education/ContentArticlePage'));

export const Shell = ({ authenticated }) => (
  <>
    {/* <ATTMessaging /> */}
    <main>
      <RouterOutlet routes={routes} ssoauthenticated={authenticated} />
    </main>
  </>
);

export const checkPreloginRedirect = ({ history }) => {
  let preloginRedirectUrl = localStorage.getItem('preloginRedirectUrl');
  if (preloginRedirectUrl) {
    localStorage.removeItem('preloginRedirectUrl');
    localStorage.setItem('preloginRedirected', 1);
    history.push(preloginRedirectUrl);
  }
};

export const routes = [
  {
    path: '/gallery',
    component: GalleryPage,
    canEnter: isAuthenticated,
    fallback: '/landing',
  },
  {
    path: '/',
    exact: true,
    redirectTo: '/gallery',
  },

  {
    path: '/education',
    exact: true,
    component: EducationPage,
    canEnter: isSSOAuthenticated,
    fallback: '/landing',
  },

  {
    path: '/education/:ostype/:topic',
    exact: true,
    component: ContentArticlePage,
  },

  {
    component: LoginPage,
  },
];

export const checkAutoLogin = ({ session, history }) => {
  if (session && session.auth) {
    history.push('/gallery');
  }
};
export default compose(
  withEffect(checkPreloginRedirect, true),
  withEffect(() => {
    initAnalytics('ATT', 'prod', {
      appName: 'PhotoStorage',
      disableWriters: [],
    });
  }, true),
  withEffect(checkAutoLogin, true),
  withRouter,
  getPropsFromStore(({ session }) => ({
    ssoauthenticated: session.ssoauthenticated,
    auth: session.auth,
  }))
)(Shell);
