import React from 'react';
import ReactDOM from 'react-dom';

// Fetch API polyfill
import 'whatwg-fetch';

// Roboto font
import 'typeface-roboto';

import App from './core/App';
import brand from './core/config/brand';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
document.title = brand.appName;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
