import { http } from '@reactorlib/core';

import { getClientInfo, generateDeviceID } from './clientInfoService';
import api from './config/api';
import brand from '../core/config/brand';

import { publishAnonEvent } from './reportingService';

import { getPersonaTokenSession } from './sessionService';
const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;
const personaToken = getPersonaTokenSession();

export const headers = {
  ApiKey: api.key,
  CarrierId: brand.carrierId,
  Locale: getClientInfo().locale,
};

export const authApi = http(api.url.authToken, headers);
export const loginApi = http(api.url.login, headers);
const version = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0';
let isSourceLogin;

export const fetchLogin = async (username, uniqueId, password, otp) => {
  isSourceLogin = true;
  return loginApi.post(
    {
      body: {
        emailId: username,
        password,
        otp,
        uniqueId,
        carrierId: 'aff',
        locale: getClientInfo().locale,
        devices: [
          {
            applicationDetails: {
              appType: 'WEB',
              appVersion: version,
              deviceOsType: getClientInfo().osName,
            },
            displayName: 'WEB',
            duid: `${username}_${uniqueId}`,
          },
        ],
      },
    },
    response => {
      publishAnonEvent({
        eventType: 'APIResponse',
        apiName: 'Login',
        apiCategory: 'Account',
        responseTime: 0,
        httpStatusCode: response.status,
        httpStatusMessage: response.statusText
          ? response.statusText
          : 'Unable to connect',
      });
    }
  );
};

export const fetchAuthToken = async (username, uniqueId, devicePassword) => {
  return authApi.post(
    {
      body: {
        user: `${username}_${uniqueId}`,
        password: devicePassword,
      },
    },
    response => {
      publishAnonEvent({
        eventType: 'APIResponse',
        apiName: 'AuthToken',
        apiCategory: 'Account',
        responseTime: 0,
        httpStatusCode: response.status,
        httpStatusMessage: response.statusText
          ? response.statusText
          : 'Unable to connect',
      });
    }
  );
};

export const login = async (username, password, otp) => {
  const uniqueId = await generateDeviceID();

  const {
    devicePasswordGuid,
    otpSent,
    mobile,
    status,
    mdn,
    error,
    subscriptionType,
    externalReferenceId,
  } = await fetchLogin(username, uniqueId, password, otp);
  if (!devicePasswordGuid)
    return {
      denied: true,
      otpdenied: otp !== null,
      username,
      otpSent,
      mobile,
      email: username,
      password,
      status,
      mdn,
      error,
      subscriptionType,
      externalReferenceId,
    };
  const auth = await authenticate(username, devicePasswordGuid, mdn);
  const response = {
    ...auth,
    subscriptionType,
    externalReferenceId,
  };
  return response;
};

export const authenticate = async (username, devicePassword, mdn) => {
  const uniqueId = await generateDeviceID();

  const { authToken, accountId, deviceId } = await fetchAuthToken(
    username,
    uniqueId,
    devicePassword
  );
  if (!isSourceLogin) {
    sendAccountPrvOpt(authToken, accountId, uniqueId);
  }
  return {
    auth: authToken,
    accountId,
    deviceId,
    mdn,
    uniqueId,
  };
};

export const validate = async (email, password, otp, uniqueId) => {
  const url = api.url.validate;

  const settings = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ApiKey: api.key,
      Locale: getClientInfo().locale,
      CarrierId: 'AFF',
      Authorization: '',
    }),
    body: JSON.stringify({
      uniqueId: uniqueId,
      devices: [
        {
          applicationDetails: {
            appType: 'WEB',
            appVersion: version,
            deviceOsType: getClientInfo().osName,
          },
          displayName: 'WEB',
          duid: email + '_' + uniqueId,
        },
      ],

      emailId: email,
      password: password,
      preValidated: 'false',
      locale: getClientInfo().locale,
      carrierId: 'AFF',
      otp: otp,
    }),
  };

  const response = await fetch(url, settings);

  return await response.json();
};

export const verifyOTP = async (email, password, otp) => {
  const uniqueId = await generateDeviceID();

  const {
    devicePasswordGuid,
    status,
    mdn,
    subscriptionType,
    externalReferenceId,
  } = await validate(email, password, otp, uniqueId);

  if (!devicePasswordGuid)
    return {
      denied: true,
      status,
    };

  const auth = await authenticate(email, devicePasswordGuid, mdn);

  return { ...auth, externalReferenceId, subscriptionType };
};

export const logout = async (username, accountId, deviceId, auth) => {
  const headers = {
    ApiKey: api.key,
    CarrierId: brand.carrierId,
    Locale: getClientInfo().locale,
    Authorization:
      enablePersona && personaToken && personaToken.accessToken
        ? personaToken.accessToken
        : auth,
    AccountId: accountId,
    DeviceId: deviceId,
  };

  const uniqueId = await generateDeviceID();

  const logoutUrl = http(api.url.logoutApi, headers);

  await logoutUrl
    .post(
      {
        body: {
          duid: `${username}_${uniqueId}`,
        },
      },
      response => {
        publishAnonEvent({
          eventType: 'APIResponse',
          apiName: 'Logout',
          apiCategory: 'Account',
          responseTime: 0,
          httpStatusCode: response.status,
          httpStatusMessage: response.statusText
            ? response.statusText
            : 'Unable to connect',
        });
      }
    )
    .catch(function (error) {
      // Error handling here!
    });

  return true;
};

const sendAccountPrvOpt = async (auth, accountId, uniqueId) => {
  const url = api.url.saveAccountApi.replace('ACCOUNT_ID', accountId);
  const settings = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      ApiKey: api.key,
      Locale: getClientInfo().locale,
      CarrierId: brand.carrierId,
      Authorization:
        enablePersona && personaToken && personaToken.accessToken
          ? personaToken.accessToken
          : auth,
    }),
    body: JSON.stringify({
      privacyFlag: localStorage.getItem('ppFlag'),
      distinctId: uniqueId,
    }),
  };

  const response = await fetch(url, settings);
  return await response.json();
};
