import { http } from '@reactorlib/core';

import api from './config/api';
import brand from '../core/config/brand';
import { publishAnonEvent } from './reportingService';

import { getPersonaTokenSession } from './sessionService';
const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;
const personaToken = getPersonaTokenSession();

export const getStorageInfo = async (auth, accountid, customerId) => {
  // ATT uses anchor ID to get Account related storage info from Cognito
  const url = api.url.storageFiles.replace('ACCOUNT_ID', accountid);

  const headers = {
    ApiKey: api.key,
    CarrierId: brand.carrierId,
    Locale: brand.locale,
    Authorization:
      enablePersona && personaToken && personaToken.accessToken
        ? personaToken.accessToken
        : auth,
    //UseCognito: 'WEB',
    //CustomerId: customerId,
    'X-Asurion-WebApiKey': api.asurionWebApiKey,
    'X-Asurion-CarrierId': brand.carrierId,
  };

  const storageApi = http(url, headers);

  const storageResponse = await storageApi.get({}, response => {
    publishAnonEvent({
      eventType: 'APIResponse',
      apiName: 'Storage',
      apiCategory: 'Account',
      responseTime: 0,
      httpStatusCode: response.status,
      httpStatusMessage: response.statusText ? response.statusText : 'Unable to connect',
    });
  });

  const {
    storageLimitInMB,
    storageUsedInMB,
    numberOfFiles,
    storageDisplayName,
    planName,
    ...other
  } = storageResponse;

  return {
    storageLimitInMB,
    storageUsedInMB,
    numberOfFiles,
    storageDisplayName,
    planName,
    _meta: other,
  };
};
