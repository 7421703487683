import React from 'react';
import {
  compose,
  withEffect,
  getPropsFromContext,
  withRouter,
  getPropsFromStore,
} from '@reactorlib/core';
import { ExternalLink } from '@reactorlib/mui';
import { publishAnonEvent } from '../services/reportingService';
import { Paper, Typography } from '@material-ui/core';
import QuickTips from '@soluto-asurion/one-service-quick-tips-react';

import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import WomanPicImg from './assets/woman-taking-left-pic.jpg';
import AppBkgrndImg from './assets/app-bg.png';
import MobileAppImg from './assets/mobile-app.png';
import Gallery from './assets/gallery.png';
import LightHouse from './assets/lighthouse.png';
import AppStore from './assets/app-store.svg';
import AndroidStore from './assets/google-play.svg';
import Footer from './Footer';

import quickTips from './config/quickTips';

import { withAnalytics } from 'react-shisell';

import { forceLogin } from '@soluto-asurion/one-service-header-react';

import { isBrowser, isMobile, isIOS, isAndroid } from 'react-device-detect';
import { UserManager } from 'oidc-client';
import personaConfig from '../core/config/persona';

export const LandingPage = ({
  classes,
  downloadPlayStoreLink,
  downloadAppStoreLink,
  analytics,
}) => {
  const authRedirect = (personaConfig) => {
    const authClient = new UserManager(personaConfig.clientConfig);
    authClient.signinRedirect().catch(() => {
      authClient.signinRedirectCallback();
    });
  }

  const mxGalleryRedirect = (location) => {
    const url = encodeURI(location);
    window.location.assign(url);
  }

  const handleButtonClick = (label, preloginRedirectUrl) => {
    if (preloginRedirectUrl) {
      localStorage.setItem('preloginRedirectUrl', preloginRedirectUrl);
    }

    publishAnonEvent({
      eventType: 'ButtonPressed',
      button: label,
      screen: 'PhotoStorageLandingPage',
    });

    analytics.dispatcher
      .createScoped('PhotoStorage')
      .withExtra('button', label)
      .withExtra('screen', 'PhotoStorageLandingPage')
      .dispatch('ButtonPressed');

    (preloginRedirectUrl) ? authRedirect(personaConfig) : mxGalleryRedirect(process.env.REACT_APP_MX_GALLERY);
  };

  const handleLinkClick = label => {
    publishAnonEvent({
      eventType: 'LinkClicked',
      link: label,
      screen: 'PhotoStorageLandingPage',
    });

    analytics.dispatcher
      .createScoped('PhotoStorage')
      .withExtra('link', label)
      .withExtra('screen', 'PhotoStorageLandingPage')
      .dispatch('LinkClicked');
  };

  return (
    <>
      <Paper square elevation={0} className={[classes.root, classes.hero].join(' ')}>
        <Grid
          container
          alignItems="center"
          justify="center"
          alignContent="flex-start"
          className={classes.containerBkgdDark}
          spacing={0}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.itemOrder1}>
            <Grid item className={classes.proText} role="presentation">
              <Typography
                variant="subtitle1"
                align="center"
                component="div"
                gutterBottom
                className={[
                  classes.gridItems,
                  classes.gridItemsRecHL,
                  classes.boldFont,
                ].join(' ')}
                tabIndex="0"
                aria-label="Photo Storage"
                role="text"
              >
                PHOTO STORAGE
              </Typography>
              <Typography
                variant="h4"
                align="center"
                component="div"
                gutterBottom
                className={[
                  classes.gridItems,
                  classes.gridTitle,
                  classes.gridTitleLarge,
                ].join(' ')}
                tabIndex="0"
                aria-label="Shoot like a pro store like a pro"
                role="text"
              >
                Shoot like a pro. <br />
                Store like a pro.
              </Typography>
              <Grid
                container
                spacing={24}
                className={classes.gridItemButtonsBottomMargin}
              >
                <Grid item className={classes.gridItemButtons}>
                  <Button
                    variant="contained"
                    aria-label="Click to get started"
                    aria-hidden="false"
                    className={classes.actionButton}
                    onClick={() => handleButtonClick('GetStarted')}
                  >
                    Get started
                  </Button>
                </Grid>
                <Grid item className={classes.gridItemButtons}>
                  <Button
                    variant="contained"
                    aria-label="Click to view my photos"
                    aria-hidden="false"
                    className={classes.viewButton}
                    onClick={() => handleButtonClick('Photos')}
                  >
                    View my photos
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.itemOrder2}>
            <Paper
              elevation={0}
              className={classes.imageContainer}
              aria-hidden="false"
              aria-label="woman taking pic"
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper
        square
        elevation={0}
        className={[classes.root, classes.appImageContainer].join(' ')}
      >
        {/* {isBrowser ? ( */}
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          // alignItems="center"
          // justify="center"
          // alignContent="flex-start"
          spacing={0}
        >
          <Grid item sm={12} xs={12} md={6} lg={6} className={classes.centerContent}>
            {/* <Typography component="div"> */}
            <img
              alt="mobile application"
              src={MobileAppImg}
              aria-hidden="true"
              className={classes.mobileAppImage}
            />
            {/* </Typography> */}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={[classes.sectionItem, classes.appText].join(' ')}
            role="presentation"
          >
            {/* <Grid item className={classes.appText} role="presentation"> */}
            <Typography
              variant="body2"
              align="center"
              component="div"
              gutterBottom
              className={[
                classes.gridItemsHL,
                classes.gridItemsHL2,
                classes.boldFont,
              ].join(' ')}
              tabIndex="0"
              role="text"
              aria-label="Protect your memories"
            >
              PROTECT YOUR MEMORIES
            </Typography>
            <Typography
              variant="h4"
              align="center"
              component="div"
              gutterBottom
              className={[classes.gridItemsSH, classes.gridTitle].join(' ')}
              role="text"
              tabIndex="0"
              aria-label="Unlimited storage for endless memories"
            >
              Unlimited storage for <br />
              endless memories.
            </Typography>
            <Typography
              variant="h6"
              align="center"
              component="div"
              gutterBottom
              tabIndex="0"
              className={classes.gridItemsSH}
              role="text"
              aria-label="Save your pictures and videos with the AT&T Photo Storage app, so you
                  never run out of space again. Plus, a ProTech is one tap away to answer
                  your photography questions"
            >
              Save your pictures and videos with the AT&T Photo Storage&#42; app , so you
              never run out of space again. Plus, a ProTech is one tap away to answer your
              photography questions.
            </Typography>
            <Grid container spacing={8} className={classes.centerContent}>
              {isBrowser ? (
                <>
                  <Grid item xs={12} md={6} lg={4} className={classes.appStoreIconDiv}>
                    <Typography component="div" className={classes.imageDiv}>
                      <ExternalLink
                        role="presentation"
                        aria-label="click to download photo storage app on apple store"
                        href={downloadAppStoreLink}
                        onClick={() => handleLinkClick('DownloadApp')}
                      >
                        <img
                          alt="apple store icon"
                          src={AppStore}
                          className={classes.storeImg}
                        />
                      </ExternalLink>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography component="div" className={classes.imageDiv}>
                      <ExternalLink
                        role="presentation"
                        aria-label="click to download photo storage app on google play"
                        href={downloadPlayStoreLink}
                        onClick={() => handleLinkClick('DownloadApp')}
                      >
                        <img
                          alt="android store icon"
                          src={AndroidStore}
                          className={classes.storeImg}
                        />
                      </ExternalLink>
                    </Typography>
                  </Grid>
                </>
              ) : isIOS ? (
                <Grid item sm={12} md={12}>
                  <Typography component="div" className={classes.imageDiv}>
                    <ExternalLink
                      role="presentation"
                      aria-label="click to download photo storage app on apple store"
                      href={downloadAppStoreLink}
                      onClick={() => handleLinkClick('DownloadApp')}
                    >
                      <img
                        alt="apple store icon"
                        src={AppStore}
                        className={classes.storeImg}
                      />
                    </ExternalLink>
                  </Typography>
                </Grid>
              ) : (
                <Grid item sm={12} md={12}>
                  <Typography component="div" className={classes.imageDiv}>
                    <ExternalLink
                      role="presentation"
                      aria-label="click to download photo storage app on google play"
                      href={downloadPlayStoreLink}
                      onClick={() => handleLinkClick('DownloadApp')}
                    >
                      <img
                        alt="android store icon"
                        src={AndroidStore}
                        className={classes.storeImg}
                      />
                    </ExternalLink>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.disclaimer}>
                <Typography
                  component="div"
                  variant="caption"
                  tabIndex="0"
                  role="text"
                  aria-label="There may be limitations on the size of each video that is to be
                      backed up and secured"
                >
                  &#42;There may be limitations on the size of each video that is to be
                  backed up and secured
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ) : (
          <Grid
            container
            justify="space-between"
            alignContent="flex-start"
            alignItems="center"
            spacing={0}
            xs={12}
            md={12}
            lg={6}
            className={classes.downloadMobile}
          >
            <Grid item className={classes.proText}>
              <Typography
                variant="subtitle1"
                align="center"
                component="div"
                gutterBottom
                className={[
                  classes.gridItems,
                  classes.productDescTitle,
                  classes.gridTitle2,
                  classes.boldFont,
                ].join(' ')}
                tabIndex="0"
              >
                PROTECT YOUR MEMORIES
              </Typography>
              <Typography
                variant="h4"
                align="center"
                component="div"
                gutterBottom
                className={[classes.gridItems, classes.gridTitle2].join(' ')}
                role="text"
                tabIndex="0"
              >
                Unlimited storage for <br />
                endless memories.
              </Typography>
              <Typography
                variant="h6"
                align="center"
                component="div"
                gutterBottom
                className={classes.gridItems}
                tabIndex="0"
              >
                Save your pictures and videos with the AT&T Photo Storage app&#42;, so you
                never run out of space again. Plus, a ProTech is one tap away to answer
                your photography questions.
              </Typography>
              {isIOS && (
                <Grid item xs={12}>
                  <Typography component="div" className={classes.imageDiv}>
                    <ExternalLink
                      href={downloadAppStoreLink}
                      onClick={() =>
                        publishAnonEvent({
                          eventType: 'LinkClicked',
                          link: 'DownloadApp',
                          screen: 'PhotoStorageLandingPage',
                        })
                      }
                    >
                      <img
                        alt="apple store icon"
                        src={AppStore}
                        className={classes.storeImg}
                        role="button"
                        aria-label="click to download photo storage app on apple store"
                      />
                    </ExternalLink>
                  </Typography>
                </Grid>
              )}
              {isAndroid && (
                <Grid item xs={12}>
                  <Typography component="div" className={classes.imageDiv}>
                    <ExternalLink
                      href={downloadPlayStoreLink}
                      onClick={() =>
                        publishAnonEvent({
                          eventType: 'LinkClicked',
                          link: 'DownloadApp',
                          screen: 'PhotoStorageLandingPage',
                        })
                      }
                    >
                      <img
                        alt="android store icon"
                        src={AndroidStore}
                        className={classes.storeImg}
                        role="button"
                        aria-label="click to download photo storage app on google play"
                      />
                    </ExternalLink>
                  </Typography>
                </Grid>
              )}
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.disclaimer}>
                  <Typography component="div" variant="caption" tabIndex="0" role="text">
                    &#42;There may be limitations on the size of each video that is to be
                    backed up and secured
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )} */}
      </Paper>

      <Paper square elevation={0} className={classes.root}>
        {/* {isBrowser ? ( */}
        <Grid
          container
          alignItems="center"
          justify="center"
          alignContent="flex-start"
          className={classes.containerBkgdLight}
          spacing={0}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={[
              classes.managePhoto,
              classes.proText,
              classes.sectionItem,
              classes.itemOrder1,
            ].join(' ')}
            role="presentation"
          >
            <Typography
              variant="subtitle1"
              align="center"
              component="div"
              gutterBottom
              className={[
                classes.gridItemsHL,
                classes.gridItemsHL2,
                classes.boldFont,
              ].join(' ')}
              tabIndex="0"
              role="text"
              aria-label="manage your photos"
            >
              MANAGE YOUR PHOTOS
            </Typography>
            <Typography
              variant="h4"
              align="center"
              component="div"
              gutterBottom
              className={[classes.gridItemsSH, classes.gridTitle].join(' ')}
              role="text"
              tabIndex="0"
              aria-label="Your saved photos available in the app and on the web"
            >
              Your saved photos, available in the app and on the web.
            </Typography>
            <Typography
              variant="h6"
              align="center"
              component="div"
              gutterBottom
              className={classes.gridItemsSH}
              tabIndex="0"
              role="text"
              aria-label="Your collection is at your fingertips and ready to share. Plus your
                  photos and videos are saved at the original resolution so you don’t
                  sacrifice quality"
            >
              Your collection is at your fingertips and ready to share. Plus your photos
              and videos are saved at the original resolution so you don’t sacrifice
              quality.
            </Typography>
            <Grid container direction="column">
              <Grid item>
                <Button
                  className={classes.learnButtons}
                  variant="contained"
                  color="primary"
                  aria-label="Click to see your photos and videos"
                  onClick={() => handleButtonClick('Account')}
                  tabIndex="0"
                >
                  See my photos and videos
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={[classes.galleryImg, classes.itemOrder2].join(' ')}
          >
            <Typography component="div">
              <img
                alt="gallery"
                src={Gallery}
                className={classes.galleryImg}
                aria-hidden="true"
              />
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper square elevation={0} className={classes.root}>
        {/* {isBrowser ? ( */}
        <Grid
          container
          justify="space-between"
          alignItems="center"
          alignContent="flex-start"
          className={classes.containerBkgdLight}
          spacing={0}
        >
          <Grid item sm={12} xs={12} md={6} lg={6}>
            <Typography component="div">
              <img
                alt="lighthouse"
                src={LightHouse}
                className={classes.lightHouseImg}
                aria-hidden="true"
              />
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            className={[classes.centerContent, classes.sectionItem, classes.proText].join(
              ' '
            )}
            role="presentation"
          >
            <Typography
              variant="subtitle1"
              align="center"
              component="div"
              gutterBottom
              className={[
                classes.gridItemsHL,
                classes.gridItemsHL2,
                classes.boldFont,
              ].join(' ')}
              tabIndex="0"
              role="text"
              aria-label="Explore"
            >
              EXPLORE
            </Typography>
            <Typography
              variant="h4"
              align="center"
              component="div"
              gutterBottom
              className={[classes.gridItemsSH, classes.gridTitle].join(' ')}
              role="text"
              tabIndex="0"
              aria-label="Up your photography game"
            >
              Up your photography game.
            </Typography>
            <Typography
              variant="h6"
              align="center"
              component="div"
              gutterBottom
              className={classes.gridItemsSH}
              tabIndex="0"
              role="text"
              aria-label="Ever wonder how some people always seem to get those amazing shots? We
                  share their secrets and make it easy for you to get those share-worthy
                  pictures too"
            >
              Ever wonder how some people always seem to get those amazing shots? We share
              their secrets and make it easy for you to get those share-worthy pictures,
              too.
            </Typography>
            <Grid container direction="column">
              <Grid item>
                <Button
                  className={classes.learnButtons}
                  variant="contained"
                  color="primary"
                  aria-label="Click to learn how to take better photos"
                  onClick={() => handleButtonClick('ExplorePhotoEducation', '/education')}
                  tabIndex="0"
                >
                  Learn how
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <QuickTips quickTips={quickTips} />
      <Footer />
    </>
  );
};

export const checkAutoLogin = ({ session, history }) => {
  // Will redirect if localstorage contains auth
  if (session && session.auth) {
    // && session.status === 'SIGNED_IN') {
    history.push('/gallery');
  }
};

export const sendAnalyticsScreenShown = ({ analytics }) => {
  publishAnonEvent({
    eventType: 'ScreenShown',
    screen: 'PhotoStorageLandingPage',
  });

  analytics.dispatcher
    .createScoped('PhotoStorage')
    .withExtra('screen', 'PhotoStorageLandingPage')
    .dispatch('ScreenShown');
};

export const styles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
    flexGrow: 1,
    fontFamily: theme.palette.font.reg,
    maxWidth: '1440px',
    margin: 'auto',
  },
  hero: {
    maxWidth: '100vw',
  },
  itemOrder1: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  itemOrder2: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  containerBkgdDark: {
    backgroundColor: '#1D2329',
  },
  containerBkgdLight: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
    paddingBottom: 11 * theme.spacing.unit,
  },
  recommendationContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginBottom: 7 * theme.spacing.unit,
    },
    flexBasis: '100%',
    maxWidth: '100%',
    paddingLeft: 10 * theme.spacing.unit,
  },
  gridItems: {
    color: 'white',
    fontFamily: theme.palette.font.reg,
    textAlign: 'left',
    marginBottom: 2 * theme.spacing.unit,
  },
  gridItemTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 5 * theme.spacing.unit,
    },
  },
  gridTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 4 * theme.spacing.unit,
    },
    fontSize: 5 * theme.spacing.unit,
  },
  gridTitleLarge: {
    [theme.breakpoints.up('md')]: {
      fontSize: 7.5 * theme.spacing.unit,
    },
  },
  gridTitle2: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 4 * theme.spacing.unit,
    },
    fontSize: 4.25 * theme.spacing.unit,
  },
  gridItemsHL2: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 1 * theme.spacing.unit,
    },
  },
  gridItemsHL: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 4.5 * theme.spacing.unit,
      marginBottom: 0,
    },
    color: theme.palette.primary.main,
    paddingTop: 3 * theme.spacing.unit,
    textAlign: 'left',
    marginBottom: 2 * theme.spacing.unit,
    fontFamily: theme.palette.font.reg,
  },
  gridItemsRecHL: {
    paddingTop: 6 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
  gridItemsSH: {
    textAlign: 'left',
    marginBottom: 2 * theme.spacing.unit,
    fontFamily: theme.palette.font.reg,
  },
  gridItemsRecSH: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5 * theme.spacing.unit,
    },
    textAlign: 'left',
    marginTop: 4 * theme.spacing.unit,
    marginBottom: 4 * theme.spacing.unit,
    fontFamily: theme.palette.font.reg,
  },
  productDescTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10 * theme.spacing.unit,
    },
  },
  proText: {
    [theme.breakpoints.down('sm')]: {
      padding: 3 * theme.spacing.unit,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      marginTop: 0,
    },
    alignItems: 'left',
    paddingLeft: 11 * theme.spacing.unit,
    paddingRight: 16 * theme.spacing.unit,
  },

  sectionItem: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
    textAlign: 'center',
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 11 * theme.spacing.unit,
    //   paddingRight: 16 * theme.spacing.unit,
    // },
    [theme.breakpoints.down('md')]: {
      padding: 3 * theme.spacing.unit,
    },
  },
  centerContent: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  downloadMobile: {
    // paddingTop: 4 * theme.spacing.unit,
    fontFamily: theme.palette.font.reg,
  },
  appImg: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      width: '100%',
      padding: 0,
      textAlign: 'center',
      marginBottom: 2.5 * theme.spacing.unit,
      marginTop: 2.5 * theme.spacing.unit,
    },
    alignItems: 'left',
    paddingLeft: 10 * theme.spacing.unit,
    paddingRight: 16 * theme.spacing.unit,
    marginTop: 2.5 * theme.spacing.unit,
  },
  mobileAppImage: {
    [theme.breakpoints.down('md')]: {
      width: '54vw',
    },
    // [theme.breakpoints.up('lg')]: {
    //   height: '75vh',
    // },
    marginTop: 2.5 * theme.spacing.unit,
  },
  galleryImg: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 2 * theme.spacing.unit,
      marginLeft: 1.2 * theme.spacing.unit,
      height: '50vh',
      textAlign: 'center',
    },
    height: '70vh',
    paddingLeft: 0,
    marginTop: 6 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    textAlign: 'right',
    paddingRight: 0,
  },
  learnButtons: {
    [theme.breakpoints.up('md')]: {
      float: 'left',
      fontSize: 2 * theme.spacing.unit,
      padding: '0.75rem 1.5rem',
      lineHeight: '150%',
    },
  },
  lightHouseImg: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      textAlign: 'center',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      marginLeft: '0px',
    },

    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${LightHouse})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      overflow: 'hidden',
      transform: 'scale(1)',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      width: '100vw',
    },

    [theme.breakpoints.up('md')]: {
      width: '50vw',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      marginLeft: '0px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '50vw',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      marginLeft: 5 * theme.spacing.unit,
    },
  },
  storeImg: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
    paddingLeft: 0,
    marginBottom: 7 * theme.spacing.unit,
  },
  mobileAppImg: {
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      maxHeight: '74vh',
      minheight: '72vh',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: -2 * theme.spacing.unit,
    },
  },
  // appText: {
  //   alignItems: 'left',
  //   paddingLeft: 0,
  //   paddingRight: 16 * theme.spacing.unit,
  //   paddingTop: 9 * theme.spacing.unit,
  // },
  imageContainer: {
    [theme.breakpoints.down('lg')]: {
      height: '75vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '44vh',
    },
    backgroundImage: `url(${WomanPicImg})`,
    //height: '66vh',
    height: '75vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    overflow: 'hidden',
    transform: 'scale(1)',
    borderRadius: 'unset',
  },
  appImageContainer: {
    backgroundImage: `url(${AppBkgrndImg})`,

    overflow: 'visible',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  },
  imageDiv: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 3 * theme.spacing.unit,
    },
    paddingRight: 2 * theme.spacing.unit,
  },
  appStoreIconDiv: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  gridItem2: {
    maxWidth: '70%',
  },
  gridItemButtons: {
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    maxWidth: 22 * theme.spacing.unit,
  },
  gridItemButtonsBottomMargin: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 2.5 * theme.spacing.unit,
    },
  },
  paper: {
    height: 140,
    width: 42 * theme.spacing.unit,
    padding: 1 * theme.spacing.unit,
  },
  viewButton: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },

    border: '1px solid #FFFFFF',
    backgroundColor: '#1D2329',
    color: '#FFFFFF',
    width: 20 * theme.spacing.unit,
    marginRight: 4 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    padding: [[1 * theme.spacing.unit, 2 * theme.spacing.unit]],
    textDecoration: 'none',
  },
  actionButton: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    border: '1px solid #FFFFFF',
    backgroundColor: '#FFFFFF',
    color: '#0057B8',
    width: 20 * theme.spacing.unit,
    marginRight: 4 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    padding: [[1 * theme.spacing.unit, 2 * theme.spacing.unit]],
    textDecoration: 'none',
  },
  boldFont: {
    fontFamily: theme.palette.font.bd,
  },
  disclaimer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 1 * theme.spacing.unit,
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    color: '#1D2329',
    marginBottom: 15 * theme.spacing.unit,
  },
  font16: {
    fontSize: 2 * theme.spacing.unit,
  },
  font40: {
    fontSize: 5 * theme.spacing.unit,
  },
});
export default compose(
  withRouter,
  withAnalytics,
  getPropsFromContext('config', ['downloadAppStoreLink', 'downloadPlayStoreLink']),
  getPropsFromStore(['session'], ['restoreSession']),
  withEffect(checkAutoLogin, true),
  withEffect(sendAnalyticsScreenShown, true),
  withEffect(() => {
    const satellite = window._satellite;
    if (!satellite) {
      return;
    }
    satellite.setVar('pathName', window.location.href);
    satellite.track('trackPage');
  }, true),
  withStyles(styles)
)(LandingPage);
