import React from 'react';
import { withRouter } from 'react-router';
import {
  compose,
  getPropsFromStore,
  withEffect,
  getPropsFromContext,
} from '@reactorlib/core';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { publishAnonEvent } from '../services/reportingService';
import { openEmailClient } from '../services/dataUtils';
import supportButton from './assets/support-button.svg';
import illustrationLogo from './assets/illustration-logo.svg';

export const CodeErrorPage = ({
  history,
  classes,
  page,
  errorcase,
  session,
  onboarding,
  supportEmail,
  email,
  uniqueId,
}) => {
  const blockedText =
    'Sorry, this account has been locked because of too many invalid attempts.';

  // const emailText = supportEmail.bodyText
  //   .replace('EMAIL', email)
  //   .replace('DEVICE_ID', uniqueId);

  const handleLoginClick = event => {
    event.preventDefault();
    publishAnonEvent({
      eventType: 'LinkClicked',
      link: 'Login',
      screen: page === 'login' ? 'LoginError' : ' OnboardingError',
    });
    history.push('/login');
  };

  const handleSignupClick = event => {
    event.preventDefault();
    publishAnonEvent({
      eventType: 'LinkClicked',
      link: 'Login',
      screen: page === 'login' ? 'LoginError' : ' OnboardingError',
    });
    history.push('/welcome');
  };

  const handleContactSupport = error => {
    publishAnonEvent({
      eventType: 'LinkClicked',
      link: 'GiveFeedback',
      screen: error,
    });
    openEmailClient(session);
  };

  const loginmessage =
    page === 'login'
      ? errorcase === 'invalidotp'
        ? blockedText
        : errorcase === 'sendagain'
        ? 'You have reached the maximum number of attempts to resend a verification code.'
        : blockedText
      : blockedText;

  const signupmessage =
    page === 'signup'
      ? errorcase === 'invalidotp'
        ? blockedText
        : errorcase === 'sendagain'
        ? 'You have reached the maximum number of attempts to resend a verification code.'
        : 'We noticed that you recently attempted to register without success.'
      : 'We noticed that you recently attempted to register without success.';

  const accountMessage =
    'We noticed that you recently attempted to register without success.';

  publishAnonEvent({
    eventType: 'MessageShown',
    message:
      page === 'login'
        ? loginmessage
        : page === 'signup'
        ? signupmessage
        : accountMessage,
    screen: page === 'login' ? 'LoginError' : 'OnboardingError',
  });

  return (
    <>
      <div align="center">
        <img src={illustrationLogo} className={classes.illustrationLogo} alt="logo" />
      </div>
      {page === 'signup' ? (
        <>
          <Typography variant="body2" className={classes.subheader} gutterBottom>
            {signupmessage}
          </Typography>
          <br />
          <Typography
            variant="body2"
            className={classes.supportWrapper}
            gutterBottom
            onClick={() => handleContactSupport('OnboardingError')}
          >
            <img src={supportButton} className={classes.supportButton} alt="logo" />
          </Typography>
          <Typography variant="body2" className={classes.signinlink} gutterBottom>
            Already registered?{' '}
            <Link href="/login" onClick={handleLoginClick}>
              Sign In
            </Link>
          </Typography>
        </>
      ) : page === 'login' ? (
        <>
          <Typography variant="body2" className={classes.subheader} gutterBottom>
            {loginmessage}
          </Typography>{' '}
          <br />
          <Typography
            variant="body2"
            className={classes.supportWrapper}
            gutterBottom
            onClick={() => handleContactSupport('LoginError')}
          >
            <img src={supportButton} className={classes.supportButton} alt="logo" />
          </Typography>
          <Typography variant="body2" className={classes.signinlink} gutterBottom>
            Not registered?{' '}
            <Link href="/welcome" onClick={handleSignupClick}>
              Sign up
            </Link>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {blockedText}
          </Typography>
          <Typography
            variant="body2"
            className={classes.supportWrapper}
            gutterBottom
            onClick={() => handleContactSupport('OnboardingError')}
          >
            <img src={supportButton} className={classes.supportButton} alt="logo" />
          </Typography>
          <Typography variant="body2" className={classes.signinlink} gutterBottom>
            Not registered?{' '}
            <Link href="/welcome" onClick={handleSignupClick}>
              Sign up
            </Link>
          </Typography>
        </>
      )}
    </>
  );
};

export const onPageLoad = ({ page }) => {
  publishAnonEvent({
    eventType: 'ScreenShown',
    screen: page === 'login' ? 'LoginError' : 'OnboardingError',
  });
};

export const styles = theme => ({
  subheader: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: '1rem',
  },
  signinlink: {
    padding: 1 * theme.spacing.unit,
    textAlign: 'center',
    fontSize: '1rem',
  },
  supportWrapper: {
    textAlign: 'center',
  },
  illustrationLogo: {
    width: 22 * theme.spacing.unit,
    marginBottom: 4 * theme.spacing.unit,
  },
  supportButton: {
    width: 22 * theme.spacing.unit,
    cursor: 'pointer',
  },
});

export default compose(
  withRouter,
  getPropsFromStore(['onboarding', 'session'], ['signup', 'generateAuth']),
  withEffect(onPageLoad, true),
  getPropsFromContext('config', ['supportEmail']),
  withStyles(styles)
)(CodeErrorPage);
