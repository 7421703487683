import { createEntity } from '@reactorlib/core';

import { createPersonaTokenSession } from '../../services/sessionService';

export const initialState = {
  accessToken: '',
  refreshToken: '',
  personaAuthenticated: false,
  clientAdapter: null,
  loading: false,
};

export const persona = createEntity(
  {
    createPersonaProfile: [
      ({ accessToken, refreshToken, personaAuthenticated, clientAdapter }, next) => {
        next({
          accessToken,
          refreshToken,
          personaAuthenticated,
          clientAdapter,
        });
      },
      (
        state,
        {
          accessToken = '',
          refreshToken = '',
          personaAuthenticated = false,
          clientAdapter = null,
        }
      ) => {
        createPersonaTokenSession(accessToken, refreshToken);

        return {
          ...state,
          accessToken,
          refreshToken,
          personaAuthenticated,
          clientAdapter,
        };
      },
    ],
  },
  initialState
);

export default persona;
