import React from 'react';
import { withRouter } from 'react-router';
import { compose, withState, getPropsFromStore, withEffect } from '@reactorlib/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Link,
} from '@material-ui/core';
import { isValidOtp } from '../services/formValidationService';
import { login } from '../services/authService';
import CodeErrorPage from '../welcome/CodeErrorPage';
import { publishAnonEvent } from '../services/reportingService';
import { isBrowser } from 'react-device-detect';

export const LoginVerify = ({
  error,
  setError,
  waiting,
  onSubmit,
  otp,
  setOtp,
  otpError,
  setOtpError,
  email,
  password,
  mobile,
  denied,
  classes,
  showtoast,
  setShowtoast,
  blocked,
  resendBlocked,
  setResendBlocked,
  history,
  resetLogin,
  session,
  otpblocked,
  keyPress,
  setKeyPress,
  resend,
  setResend,
  remember,
}) => {
  const handleChangeOtp = event => {
    let otpVal = '';
    if(event && event.target && event.target.value){
      otpVal = Math.max(0, parseInt(event.target.value))
      .toString()
      .slice(0, 6);
    }
    
    setOtp(isNaN(otpVal) ? '' : otpVal);
    setKeyPress(false);
  };
  const handleKeyPressOtp = e => {
    if (e.key === 'e') {
      e.target.value.replace(/[^0-9]*/g, '');
      e.preventDefault();
    }
  };
  const handleResend = event => {
    event.preventDefault();
    publishAnonEvent(
      {
        eventType: 'LinkClicked',
        link: 'ResendCode',
        screen: 'OTPEntry',
      },
      'Return'
    );
    login(email, password).then(result => {
      if (result.status === 'BLOCKED') setResendBlocked(true);
    });
    publishAnonEvent(
      {
        eventType: 'DialogShown',
        dialog: 'ResendCodeSuccess',
        screen: 'OTPEntry',
      },
      'Return'
    );
    publishAnonEvent({ eventType: 'OTPSent' }, 'Return');
    setShowtoast(true);
    setResend(true);
    return false;
  };
  const handleSubmit = event => {
    event.preventDefault();
    if (!isValidOtp(otp)) {
      setOtpError(true);
      return false;
    }
    publishAnonEvent(
      {
        eventType: 'ButtonPressed',
        button: 'VerifyOTP',
        screen: 'OTPEntry',
      },
      'Return'
    );
    publishAnonEvent(
      {
        eventType: 'OTPValidate',
        screen: 'OTPEntry',
      },
      'Return'
    );
    onSubmit({ email, password, remember, otp });
    setKeyPress(true);
  };

  const handleCloseToast = event => {
    setShowtoast(false);
  };

  const handleBackClick = event => {
    event.preventDefault();
    publishAnonEvent(
      {
        eventType: 'LinkClicked',
        link: 'GoBack',
        screen: 'OTPEntry',
      },
      'Return'
    );
    resetLogin();
    history.push('/login');
  };

  const handleClickSignup = ({ history }) => event => {
    event.preventDefault();
    setResend(false);
    publishAnonEvent(
      { eventType: 'LinkClicked', link: 'SignUp', screen: 'OTPEntry' },
      'Return'
    );
    history.push('/welcome');
  };

  let errorcase = null;

  if (resendBlocked) {
    errorcase = 'sendagain';
  } else if (blocked) {
    errorcase = 'account';
  } else if (otpblocked) {
    errorcase = 'invalidotp';
  }

  if (error && keyPress && !resend) {
    publishAnonEvent(
      {
        eventType: 'MessageShown',
        message: 'Invalid otp code',
        screen: 'OTPEntry',
      },
      'Return'
    );
    publishAnonEvent(
      {
        eventType: 'OTPFail',
        message: 'Invalid otp code',
        screen: 'OTPEntry',
        httpStatusMessage: 'CODE_NOT_FOUND',
        httpStatusCode: '400',
      },
      'Return'
    );
  }

  return (
    <>
      <Grid item className={classes.backContainer}>
        <Typography>
          <Link href="/login" onClick={handleBackClick} className={classes.backLink}>
            {'<'} Back
          </Link>
        </Typography>
      </Grid>

      {errorcase ? (
        <Paper elevation={1} className={classes.root}>
          <CodeErrorPage
            page="login"
            errorcase={errorcase}
            email={email}
            uniqueId={session.uniqueId}
          />
        </Paper>
      ) : (
          <Paper elevation={isBrowser ? 1 : 0} className={classes.root}>
            <Typography variant="h6" align="center" gutterBottom className={classes.headerText}>
              Enter verification code
            </Typography>
            <Typography variant="body2" className={classes.subheader} gutterBottom>
              We sent a verification code to your phone
            </Typography>
            <Typography variant="body2" component="div" gutterBottom>
              <form id="verification" onSubmit={handleSubmit}>
                <Typography className={classes.textFieldLabels}>
                  Verification Code
                </Typography>
                <TextField
                  id="otp"
                  value={otp}
                  error={(otpError || error) && !resend}
                  helperText={
                    otpError && !resend
                      ? 'OTP invalid'
                      : error && !resend
                        ? 'Invalid code - please check your entry'
                        : ''
                  }
                  onChange={handleChangeOtp}
                  className={classes.textField}
                  required
                  fullWidth
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  type="tel"
                  onKeyPress={handleKeyPressOtp}
                />
                <Typography variant="body2" className={classes.subheader} gutterBottom>
                  Didn't get the code? Check your phone number or{' '}
                  <Link href="/" onClick={handleResend} className={classes.sendagain}>
                    Send again
                </Link>
                </Typography>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  className={classes.actionBar}
                >
                  <Grid container item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValidOtp(otp)}
                      className={classes.submit}
                    >
                      {!waiting ? (
                        ' Verify '
                      ) : (
                          <CircularProgress size={24} className={classes.progress} />
                        )}
                    </Button>
                  </Grid>
                  <Grid item container justify="center">
                    <Typography variant="body2" className={classes.signupText}>
                      Not registered?{' '}
                      <Link href="/welcome" onClick={handleClickSignup}>
                        Sign up
                    </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Typography>

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={showtoast}
              onClose={handleCloseToast}
              autoHideDuration={3000}
              bodystyle={{ minWidth: '100%' }}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Verification code has been sent.</span>}
            />
          </Paper>
        )}
    </>
  );
};

export const styles = theme => ({
  root: {
    padding: 4 * theme.spacing.unit,
    paddingBottom: 0,
    width: 50 * theme.spacing.unit,
    minHeight: 61 * theme.spacing.unit,
  },
  logo: {
    maxHeight: 8 * theme.spacing.unit,
  },
  backContainer: {
    top: 0,
    left: 0,
    padding: 2 * theme.spacing.unit,
    position: 'fixed',
  },
  backLink: {
    color: 'black',
  },
  button: {
    paddingTop: 6 * theme.spacing.unit,
    justifyContent: 'left',
    flexBasis: 'auto',
    paddingRight: theme.spacing.unit,
  },
  buttonContainer: {
    flexWrap: 'nowrap',
  },
  resendContainer: {
    paddingTop: 6 * theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  textField: {
    marginBottom: 9.3 * theme.spacing.unit,
  },
  submit: {
    minWidth: 14 * theme.spacing.unit,
    marginTop: 4 * theme.spacing.unit,
  },
  progress: {
    color: theme.palette.primary.contrastText,
  },
  error: {
    marginTop: -1 * theme.spacing.unit,
    marginBottom: 1 * theme.spacing.unit,
  },
  subheader: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
  },
  textFieldLabels: {
    fontSize: '1rem',
    lineHeight: '150%',
    marginTop: 1.5 * theme.spacing.unit,
    color: theme.palette.secondary.main
  },
  signupText: {
    marginTop: 1.25 * theme.spacing.unit,
    fontSize: '1rem'
  },
  headerText: {
    fontWeight: 'bold',
  },
});

export default compose(
  withRouter,
  withState('otp', ''),
  withState('otpError', false),
  withState('resend', false),
  withState('showtoast', false),
  withState('resendBlocked', false),
  withState('keyPress', false),
  withState('elevation', 1),
  getPropsFromStore(['session'], ['resetLogin']),
  withEffect(
    () => publishAnonEvent({ eventType: 'OTPSent', screen: 'OTPEntry' }, 'Return'),
    true
  ),
  withEffect(
    () => publishAnonEvent({ eventType: 'ScreenShown', screen: 'OTPEntry' }, 'Return'),
    true
  ),
  withStyles(styles)
)(LoginVerify);
