import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  compose,
  getPropsFromStore,
  withStore,
  withContext,
  RouterOutlet,
  withEffect,
} from '@reactorlib/core';
import { withMui } from '@reactorlib/mui';
import { withStyles } from '@material-ui/core';

import Shell from './Shell';
import session from './store/session';
import storageInfo from './store/storageInfo';
import resetPassword from './store/resetPassword';
import userProfile from './store/userProfile';
import persona from './store/persona';
import onboarding from '../welcome/store/onboarding';

import brand from './config/brand';
import theme from './config/theme';

import './assets/font.css';
import LandingPage from './LandingPage';
import Header from './Header';
// import { ATTMessaging } from '@soluto-asurion/one-service-messaging-react';
import LoggedOut from './LoggedOut';
import AutoLogin from './AutoLogin';

export const isNotAuthenticated = ({ auth }) => auth === null || auth === 'undefined';

export const isSSONotAuthenticated = ({ ssoauthenticated }) =>
  ssoauthenticated === null || ssoauthenticated === 'undefined';

const enablePersona = process.env.REACT_APP_ENABLE_PERSONA_INTEGRATION || false;

export const routes = [
  {
    path: '/landing',
    component: LandingPage,
  },
  {
    path: '/autologin',
    component: JSON.parse(enablePersona) ? AutoLogin : LandingPage,
  },
  {
    path: '/',
    exact: true,
    redirectTo: '/landing',
  },
  {
    path: '/logout',
    component: LoggedOut,
  },
  {
    component: Shell,
  },
];

export const App = ({ authenticated }) => (
  <>
    <Router basename="/photo-storage">
      <>
        {<Header />}
        <RouterOutlet routes={routes} auth={authenticated} />
      </>
    </Router>
  </>
);

export const styles = theme => ({
  '@font-face': brand.fonts,
});

export const checkAutoLogin = ({ session, history }) => {
  if (session && session.auth) {
    history.push('/gallery');
  }
};

export default compose(
  withStore({ session, storageInfo, resetPassword, userProfile, persona, onboarding }),
  getPropsFromStore(
    ({ session }) => ({ auth: session.auth, ssoauthenticated: session.ssoauthenticated }),
    ['restoreSession']
  ),
  withEffect(checkAutoLogin, true),
  withEffect(({ restoreSession }) => restoreSession(), true),
  withContext('config', () => ({ ...brand })),
  withMui(theme),
  withStyles(styles)
)(App);
