import { http } from '@reactorlib/core';

import api from './config/api';
import brand from '../core/config/brand';

import { publishAnonEvent } from './reportingService';

export const verify = async username => {
  const url = api.url.resetPasswordVerify;

  const headers = {
    ApiKey: api.key,
    CarrierId: brand.carrierId,
    Locale: brand.locale,
  };

  const verifyApi = http(url, headers);

  const response = await verifyApi.post(
    {
      body: {
        user: username,
      },
    },
    response => {
      publishAnonEvent({
        eventType: 'APIResponse',
        apiName: 'VerifyCode',
        apiCategory: 'Account',
        responseTime: 0,
        httpStatusCode: response.status,
        httpStatusMessage: response.statusText
          ? response.statusText
          : 'Unable to connect',
      });
    }
  );
  const { status, error } = response;
  return {
    status,
    username: username,
    error,
  };
};
