export const mockApi = process.env.REACT_APP_MOCK_API && {
  signup: '',
  validate: '',
  login: 'https://demo3317325.mockable.io/login',
  authToken: 'https://demo3317325.mockable.io/authToken',
  fetchFiles: 'https://demo3317325.mockable.io/filter',
  storageFiles: '',
  sendDownloadSMS: '',
  resetPasswordVerify: 'false',
  eventApiV1: '',
  eventApiV2: '',
  webLaunched: 'false',
};

export default {
  key: process.env.REACT_APP_API_KEY || '1YQ4XR08WVNDWBKU',
  reporting: {
    key: 'FID5r6gPazXm7uK2x0R2',
    auth: 'i4Ud5mTHqdyfrQjiQXMRXxAhp8VHXq6iIIjzgjXtibi9izW5ZTA0zqRQjPgY',
  },
  campaign: {
    key: '56IC3JWHU575ES2XNZRN',
    auth: 'NWJ9ZJ5F8YHA70XKTQMIX7GHJLA581W7TLOZ8WP8',
  },
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN || '664ffe7a8bdf85207bda500ac4251485',
  },
  asurionWebApiKey:
    process.env.REACT_APP_ASURION_WEBAPIKEY || '2ec2b76AE37b373Db6bafd8c033ed8178C033Ed8', //ec2b76AE37b373Db6bafd8c033ed8178C033Ed8',
  resetPasswordValidate: false,
  resetPassEventSent: false,
  url: mockApi || {
    signup:
      process.env.REACT_APP_SIGNUP_API ||
      'https://protectioncenter.attprotechqa.com/api/v1/wsignup',
    provision:
      process.env.REACT_APP_PROVISION_API ||
      'https://memories-qa.asurionpa.com/acco/v1/accounts/provision',
    validate:
      process.env.REACT_APP_VALIDATE_API ||
      'https://protectioncenter.attprotechqa.com/api/v1/wsignupValidate',
    validateJWTToken:
      process.env.REACT_APP_VALIDATE_JWT_TOKEN_API ||
      'https://protectioncenter.attprotechqa.com/api/v1/wvalidate',
    login:
      process.env.REACT_APP_LOGIN_API ||
      'https://protectioncenter.attprotechqa.com/api/v1/wsignin',
    authToken:
      process.env.REACT_APP_AUTH_TOKEN_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/auth/v1/security/authToken',
    fetchFiles:
      process.env.REACT_APP_FETCH_FILES_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/file/v2/accounts/ACCOUNT_ID/files/filter',
    storageFiles:
      process.env.REACT_APP_STORAGE_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/file/v2/accounts/ACCOUNT_ID/storage',
    sendDownloadSMS:
      process.env.REACT_APP_DOWNLOADSMS_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/acco/v2/accounts/app',
    resetPasswordVerify:
      process.env.REACT_APP_RESET_PWD_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/acco/v1/accounts/vcode',
    eventApiV1:
      process.env.REACT_APP_EVENTS_V1_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-apirepo/qa/v1/clientReportingEvents',
    eventApiV2:
      process.env.REACT_APP_EVENTS_V2_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/repo/v2/clientReportingEvents',
    logoutApi:
      process.env.REACT_APP_LOGOUT_API ||
      'https://d22rzm7hwr4qxy.cloudfront.net/photo-storage-api/acco/v2/accounts/logout',
    histApi:
      process.env.REACT_APP_HIST_API ||
      'https://memories-qa.asurionpa.com/memories-experts/print/v1/MESSAGEID/CAMPAIGNID/ACCOUNTID/FILEID/hist',
  },
};
