export const isValidMobileNumber = mdn => {
  mdn = mdn.replace(/[^0-9]/g, '');
  return /^(0|[1-9][0-9]{9})$/i.test(mdn);
};
export const isValidEmail = email =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isValidPassword = password => /^\S{6,15}$/.test(password);

export const isValidOtp = otp => /^[0-9]{5,6}$/.test(otp);

export const isValidOtpEmail = otp => /^[0-9]{5}$/.test(otp);

export const formatPhoneNumber = mdn => {
  if (!mdn || mdn === 'undefined') return '';

  const match = mdn.match(/^(\d{3})(\d{3})(\d{4})$/);
  return '(***)*** - ' + match[3];
};
