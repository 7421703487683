const colors = {
  primary: '#0057B8',
  secondary: '#CCEEFB',
  secondary2: '#ffffff',
  dark: '#e6b909',
  brown: '#454545',
  blue: '#00aeef',
  grey: '#949599',
  secondaryGrey: '#757575',
  darkGrey: '#3f3f3f',
  lightGrey: '#e7e7e8',
  brownGrey: '#8f8f8f',
  brickRed: '#cf2a2a',
};

const fonts = {
  sans: 'ATTAleckSans_Rg,sans-serif',
  sansMd: 'ATTAleckSans_Md, sans-serif',
  sansBd: 'ATTAleckSans_Bd, sans-serif',
};

// Image selector colors
export const selectorColor = colors.primary;
export const selectorBgColor = 'rgba(0, 0, 0, 0.1)';

export default {
  palette: {
    primary: {
      main: colors.primary,
      light: colors.secondary2,
    },
    secondary: {
      main: colors.brown,
    },
    font: {
      reg: fonts.sans,
      md: fonts.sansMd,
      bd: fonts.sansBd,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: fonts.sans,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 769,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: colors.blue,
        '&:hover': {
          color: colors.brown,
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: fonts.sansBd,
      },
      contained: {
        borderRadius: '10rem',
        textTransform: 'none',
        boxShadow: 'none',
      },
      outlined: {
        borderRadius: '10rem',
        textTransform: 'none',
        textDecoration: 'none',
        fontWeight: 800,
      },
      containedPrimary: {
        '&:disabled, &:disabled:hover': {
          backgroundColor: colors.grey,
        },
        color: colors.secondary2,
      },
      textPrimary: {
        color: colors.blue,
        '&:hover': {
          backgroundColor: colors.darkGrey,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: colors.brickRed,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.brownGrey,
        '&$focused': {
          color: colors.brownGrey,
        },
        '&$error': {
          color: colors.brickRed,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderColor: colors.brownGrey,
        '&$focused $notchedOutline': {
          borderColor: colors.brownGrey,
        },
      },
    },

    MuiMenuItem: {
      root: {
        fontFamily: fonts.sans,
      },
    },
    MuiMobileStepper: {
      dotActive: {
        backgroundColor: colors.brown,
      },
    },
    MuiTypography: {
      root: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      caption: {
        fontSize: '0.875rem',
        color: '#1D2329',
      },
    },
  },
  wallpaper: {
    color: colors.secondary,
  },
  greywallpaper: {
    color: colors.grey,
  },
  customFonts: {
    primary: fonts.sans,
  },
  sideNav: {
    color: colors.secondaryGrey,
  },
  snackbarBgColor:{
    color: colors.primary
  },
  snackbarTextColor:{
    color: '#fff'
  },
  okayTextColor:{
    color: colors.primary
  },
  spinnerColor:{
    color: colors.primary
  },

  deleteTextColor:{
    color: colors.brickRed
  }
};
