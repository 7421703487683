export const quickTips = {
  title: 'ProTech recommendations',
  tips: [
    {
      smallImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/woman_having_problems_with_streaming_service_mobile.jpg',
      largeImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/woman_having_problems_with_streaming_service_desktop.jpg',
      contentUrl: '/sw/content/legacy/197012',
      label: 'Home Tech',
      description: 'How to solve the most common streaming problems',
    },
    {
      smallImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/couple_using_streaming_video_service_mobile.jpg',
      largeImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/couple_using_streaming_video_service_desktop.jpg',
      contentUrl: '/sw/content/legacy/198154',
      label: 'Home Tech',
      description: 'Everything you need to know about streaming in 4K',
    },
    {
      smallImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/blurry_family_photo_mobile.jpg',
      largeImage:
        'https://d3szi52w63oe28.cloudfront.net/images/content/blurry_family_photo_desktop.jpg',
      contentUrl: '/sw/content/legacy/197043',
      label: 'Photography',
      description: '3 ways to avoid blurry photos',
    },
  ],
};

export default quickTips;
