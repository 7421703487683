import { createEntity } from '@reactorlib/core';

import { generateDeviceID } from '../../services/clientInfoService';

export const initialState = {
  accountId: '',
  username: '',
  deviceId: '',
  mobile: '',
  subscriptionType: '',
  uniqueId: '',
  userType: '',
};

export const userProfile = createEntity(
  {
    createProfile: [
      (
        { accountId, deviceId, uniqueId, userType, username, mobile, subscriptionType },
        next
      ) => {
        next({
          accountId,
          deviceId,
          uniqueId,
          userType,
          username,
          mobile,
          subscriptionType,
        });
      },
      (
        state,
        {
          accountId = '',
          deviceId = '',
          uniqueId = '',
          userType = '',
          username = '',
          mobile = '',
          subscriptionType = '',
        }
      ) => {
        return {
          ...state,
          accountId,
          deviceId,
          uniqueId,
          generateDeviceID,
          userType,
          username,
          mobile,
          subscriptionType,
        };
      },
    ],
  },
  initialState
);

export default userProfile;
