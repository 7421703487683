import { publishEvent } from './reportingService';
import { generateDeviceID } from './clientInfoService';
import brand from '../core/config/brand';

export const filterProps = (obj, keys = null) => {
  if (!keys) return obj;

  const o = {};
  keys.forEach(k => {
    if (obj[k] !== undefined) o[k] = obj[k];
  });
  return o;
};

export const mergeGroupedLists = (a, b) => {
  const c = { ...a };
  for (let k in b) {
    c[k] = c[k] ? [...c[k], ...b[k]] : b[k];
  }
  return c;
};

export const findPathName = (arr, path) => {
  const result = arr.filter(obj => obj.path === path)[0];
  return result ? result.name : 'NotFound';
};

export const getTimeSpent = session => {
  const time = new Date().getTime() - session.startTime;
  const seconds = ((time % 60000) / 1000).toFixed(0);
  return seconds;
};

export const isIPad =
  /iPad/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const downloadFileAndPublish = (image, session, userType, screen) => {
  // send event :: ActionPerformed
  publishEvent(
    session,
    getEvent(image, 'ActionPerformed', screen),
    userType
  );

  // download file
  if (image.downloadUrl) {
    performDownload(image.downloadUrl);
  }

  // send event :: SaveSuccess
  publishEvent(
    session,
    getEvent(image, 'SaveSuccess', screen),
    userType
  );
};

export const downloadZipAndPublish = (url, files, session, user, screen) => {
  // send event :: ActionPerformed
  publishEvent(
    session,
    getZipEvent(files, 'ActionPerformed', screen),
    user
  );

  // download file
  performDownload(url);

  // send event :: SaveSuccess
  publishEvent(
    session,
    getZipEvent(files, 'SaveSuccess', screen),
    user
  );
};

export const publishDeleteEvt = (files, session, user, evtType) => {
  const filesMeta = getFilesMeta(files);
  const evtObj = {
    eventType: evtType,
    action: 'CloudTrash',
    screen: 'FullView',
    source: 'Photos',
    totalFiles: files.length,
    totalFileSize: getFileSizeSum(files, 'fileSize'),
    totalPhotos: filesMeta.photoCount,
    totalPhotoSize: filesMeta.photoSize,
    totalVideos: filesMeta.videoCount,
    totalVideoSize: filesMeta.videoSize
  };
  if (['TrashSuccess'].indexOf(evtType) > -1) {
    delete evtObj.action;
  }
  publishEvent(
    session,
    evtObj,
    user
  );
}

export const publishDownloadFailEvent = (status, session, user, statusText, files, screen) => {

  const failEvent = getZipEvent(files, 'SaveFail', screen);
  failEvent.httpStatusCode = status;
  failEvent.httpStatusMessage = statusText;

  // send event :: SaveFail 
  publishEvent(
    session,
    failEvent,
    user
  );
};

export const performDownload = (url) => {
  // download file
  let a = document.createElement('a');
  a.href = url;
  setTimeout(() => a.click(), 0);
};

export const performMultiDownload = (files) => {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    var a = document.createElement('a');
    a.href = files[i].fileUrl;
    a.target = '_parent';
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } 
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    setTimeout(() => download_next(i + 1), 5000);
  }
  // Initiate the first download.
  download_next(0);
};

const getZipEvent = (files, type, screen) => {
  const filesMeta = getFilesMeta(files);
  const evtObj = {
    eventType: type,
    action: 'Save',
    screen: screen,
    source: 'Photos',
    totalFiles: files.length,
    totalFileSize: getFileSizeSum(files, 'fileSize'),
    totalPhotos: filesMeta.photoCount,
    totalPhotoSize: filesMeta.photoSize,
    totalVideos: filesMeta.videoCount,
    totalVideoSize: filesMeta.videoSize
  };
  if (['SaveSuccess', 'SaveFail'].indexOf(type) > -1) {
    delete evtObj.action;
  }
  return evtObj;
};

const getFilesMeta = (files) => {
  var [photoCount, photoSize, videoCount, videoSize] = [0, 0, 0, 0];
  files.forEach((item) => {
    if (item.fileType.includes('image')) {
      photoCount++;
      photoSize += item.fileSize;
    }
    else if (item.fileType.includes('video')) {
      videoCount++;
      videoSize += item.fileSize;
    }
  });
  return { photoCount, photoSize, videoCount, videoSize };
};

const getFileSizeSum = (items, property) => items.reduce((prev, next) => prev + next[property], 0);

const getEvent = (image, type, screen) => {
  const evtObj = {
    eventType: type,
    action: 'Save',
    screen: screen,
    source: 'Photos',
    totalFiles: '1',
    totalFileSize: image.fileSize,
    totalPhotos: image.isVideo ? '0' : '1',
    totalPhotoSize: image.isVideo ? '0' : image.fileSize,
    totalVideos: image.isVideo ? '1' : '0',
    totalVideoSize: image.isVideo ? image.fileSize : '0',
    fileType: image.isVideo ? 'Video' : 'Image',
  };
  if (type === 'SaveSuccess') {
    delete evtObj.action;
  }
  return evtObj;
};

export const getAvailableSpaceText = (storageInfo) => {
  const storageVal = (storageInfo.storageLimitInMB / 1024 - storageInfo.storageUsedInMB / 1024).toFixed(1);
  if (storageInfo.storageDisplayName === 'Unlimited') {
    return ' - unlimited storage available';
  }
  else if (storageVal <= 0) {
    return '';
  }
  else {
    return ` - ${storageVal} available`;
  }
};

export const isServerError = (statusCode) => {
  return statusCode >= 500 && statusCode < 600 ? true : false;
};

export const openEmailClient = async (session) => {
  // open email client
  const uniqueId = await generateDeviceID();

  const supportItem = brand.content.menu.protected[1];
  const a = document.createElement('a');
  a.href = supportItem.link
    .replace('ACCOUNT_ID', session.accountId)
    .replace('DEVICE_ID', session.deviceId)
    .replace('UNIQUE_ID', uniqueId);
  setTimeout(() => a.click(), 0);
};
