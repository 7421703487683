import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UAParser } from 'ua-parser-js';
import { isTablet, isBrowser } from 'react-device-detect';

// Memoized value for clientInfo
let __clientInfo = null;

export const getClientInfo = () => {
  // Return memoized value if available
  if (__clientInfo) return __clientInfo;

  const ua = new UAParser(window.navigator.userAgent);
  const os = ua.getOS();
  const device = ua.getDevice();
  const browser = ua.getBrowser();
  const formFactor = isBrowser ? 'Desktop' : isTablet ? 'Tablet' : 'Mobile';

  __clientInfo = {
    deviceModel: device.model || '',
    deviceVendor: device.vendor || '',
    device: formFactor,
    osName: os.name || '',
    osVersion: os.version || '',
    browserName: browser.name || '',
    browserVersion: browser.version || '',
    locale: navigator.language,
  };

  return __clientInfo;
};

// Memoized value for deviceID
let __deviceId = null;

export const generateDeviceID = () =>
  new Promise(resolve => {
    // Return memoized value if available
    if (__deviceId) return resolve(__deviceId);
    
    
    setTimeout(() => {


(async () => {
  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const fp = await fpPromise
  const result = await fp.get();

  // This is the visitor identifier:
  const __deviceId = result.visitorId;
  
  console.log(__deviceId);
  resolve(__deviceId);
})();
      // Fp2.get(
      //   {
      //     preprocessor: (key, value) => {
      //       if (key === 'userAgent') {
      //         const client = getClientInfo();
      //         // this one for testing only TODO : remove later
      //         // console.log(
      //         //   ' Device Make and Model ' +
      //         //     client.deviceModel +
      //         //     client.deviceVendor +
      //         //     client.osName +
      //         //     client.browserName
      //         // );
      //         return (
      //           client.deviceModel +
      //           client.deviceVendor +
      //           client.osName +
      //           client.browserName
      //         );
      //       }
      //       return value;
      //     },
      //   },
      //   components => {
      //     const values = components.map(component => component.value);
      //     __deviceId = Fp2.x64hash128(values.join(''), 31);
      //     resolve(__deviceId);
      //   }
      // );
    }, 500);
  });
